import { useEffect } from 'react';
import { useFormikContext } from 'formik';

import { getAgeFromDOB } from 'utils/date-of-birth';

const EditInitialDataFormik = (props) => {
  const {
    editData,
    setClientOptions,
    handleFindClientDetails,
    isExistingClient,
    isShareholder,
    setIsExistingClient,
    selectedExistingClientData,
    setSelectedExistingClientData,
  } = props;
  /**
   * Uses formik context
   */
  const formikContext = useFormikContext();

  /**
   * Handle initialize default data[director management]
   */
  const handleInitializeData = () => {
    if (selectedExistingClientData?._id) {
      formikContext?.setValues &&
        formikContext.setValues({
          ...(formikContext?.initialValues || {}),
          isExistingClient: isExistingClient,
          clientId:
            isExistingClient && selectedExistingClientData ? selectedExistingClientData?._id : null,

          title:
            isExistingClient && selectedExistingClientData ? selectedExistingClientData?.title : '',
          firstName:
            isExistingClient && selectedExistingClientData
              ? selectedExistingClientData?.firstName
              : '',
          middleName:
            isExistingClient && selectedExistingClientData
              ? selectedExistingClientData?.middleName
              : '',
          surName:
            isExistingClient && selectedExistingClientData
              ? selectedExistingClientData?.surName
              : '',
          preferredName:
            isExistingClient && selectedExistingClientData
              ? selectedExistingClientData?.preferredName
              : '',
          gender:
            isExistingClient && selectedExistingClientData
              ? selectedExistingClientData?.gender
              : '',
          dateOfBirth:
            isExistingClient && selectedExistingClientData?.dateOfBirth
              ? new Date(selectedExistingClientData?.dateOfBirth)
              : null,
          age:
            isExistingClient &&
            selectedExistingClientData &&
            selectedExistingClientData?.dateOfBirth
              ? getAgeFromDOB(
                  selectedExistingClientData?.dateOfBirth &&
                    new Date(selectedExistingClientData?.dateOfBirth),
                  true
                )
              : '',
          // lifeExpectancy: selectedExistingClientData?.lifeExpectancy || '',
          maritalStatus:
            isExistingClient && selectedExistingClientData
              ? selectedExistingClientData?.maritalStatus
              : '',
          hasPartner:
            isExistingClient && selectedExistingClientData
              ? selectedExistingClientData?.hasPartner
              : false,
          // idType: selectedExistingClientData?.idType || '',
          // idNumber: selectedExistingClientData?.idNumber || '',
          occupationStatus:
            isExistingClient && selectedExistingClientData
              ? selectedExistingClientData?.occupationStatus
              : '',
          occupationStartedDate:
            isExistingClient && selectedExistingClientData?.occupationStartedDate
              ? new Date(selectedExistingClientData?.occupationStartedDate)
              : null,
          occupation:
            isExistingClient && selectedExistingClientData
              ? selectedExistingClientData?.occupation
              : '',
          employerName:
            isExistingClient && selectedExistingClientData
              ? selectedExistingClientData?.employerName
              : '',
          employmentStatus:
            isExistingClient && selectedExistingClientData
              ? selectedExistingClientData?.employmentStatus
              : '',
          employmentType:
            isExistingClient && selectedExistingClientData
              ? selectedExistingClientData?.employmentType
              : '',
          hoursWorked:
            isExistingClient && selectedExistingClientData
              ? selectedExistingClientData?.hoursWorked
              : '',
          yearsInCurrentOccupation:
            isExistingClient && selectedExistingClientData
              ? selectedExistingClientData?.yearsInCurrentOccupation
              : '',
          desiredRetirementAge:
            isExistingClient && selectedExistingClientData
              ? selectedExistingClientData?.desiredRetirementAge
              : '',

          primaryEmailAddress:
            isExistingClient && selectedExistingClientData
              ? selectedExistingClientData?.primaryEmailAddress
              : '',
          homePhoneNumber:
            isExistingClient && selectedExistingClientData
              ? selectedExistingClientData?.homePhoneNumber
              : '',
          primaryMobileNumber:
            isExistingClient && selectedExistingClientData
              ? selectedExistingClientData?.primaryMobileNumber
              : '',
          hasPrivateHealthCover:
            isExistingClient && selectedExistingClientData
              ? selectedExistingClientData?.hasPrivateHealthCover
              : false,
          smoker:
            isExistingClient && selectedExistingClientData
              ? selectedExistingClientData?.smoker
              : false,
          emailWeeklyTaskSummary:
            isExistingClient && selectedExistingClientData
              ? selectedExistingClientData?.emailWeeklyTaskSummary
              : false,
          taxFileNumber:
            isExistingClient && selectedExistingClientData
              ? selectedExistingClientData?.taxFileNumber
              : '',
          clientType:
            isExistingClient && selectedExistingClientData
              ? selectedExistingClientData?.clientType
              : 'Client',
        });
    }
  };
  /**
   * Handle initialize edit model initial data [director management]
   */
  const handleInitializeEditModeData = () => {
    if (editData?._id) {
      const isExistingClient = editData?.isExistingClient && editData?.clientId;
      if (isExistingClient) {
        const clientName =
          (editData?.title &&
            editData.title +
              ' ' +
              ((editData?.surName && editData?.firstName + ' ' + editData.surName) ||
                editData.firstName)) ||
          (editData?.surName && editData?.firstName + ' ' + editData?.surName) ||
          editData?.firstName;
        setClientOptions &&
          setClientOptions((prev) => [
            {
              id: editData?.clientId,
              value: editData?.clientId,
              label: clientName,
              name: clientName,
            },
          ]);
        setIsExistingClient && setIsExistingClient(true);
        handleFindClientDetails && handleFindClientDetails(editData?.clientId);
      }
    }
  };
  /**
   * Handle initialize shareholder initial values
   */
  const handleInitializeShareholderData = () => {
    const shareholderExistingType = formikContext?.values?.shareholderExistingType;
    const isSelectedIndividualClientOrDirector =
      isExistingClient &&
      (shareholderExistingType === 'Individual' || shareholderExistingType === 'Director');
    if (isExistingClient) {
      const idKeyValueData = {
        directorId:
          isExistingClient && shareholderExistingType === 'Director'
            ? selectedExistingClientData?._id
            : null,

        clientId:
          isExistingClient && shareholderExistingType === 'Individual'
            ? selectedExistingClientData?._id
            : null,
        smsfId:
          isExistingClient && shareholderExistingType === 'SMSF'
            ? selectedExistingClientData?._id
            : null,
        trustId:
          isExistingClient && shareholderExistingType === 'Trust'
            ? selectedExistingClientData?._id
            : null,
        companyId:
          isExistingClient && shareholderExistingType === 'Company'
            ? selectedExistingClientData?._id
            : null,
      };
      const selectedIndividualClientData = {
        title:
          isSelectedIndividualClientOrDirector && selectedExistingClientData
            ? selectedExistingClientData?.title
            : '',
        firstName:
          isSelectedIndividualClientOrDirector && selectedExistingClientData
            ? selectedExistingClientData?.firstName
            : '',
        middleName:
          isSelectedIndividualClientOrDirector && selectedExistingClientData
            ? selectedExistingClientData?.middleName
            : '',
        surName:
          isSelectedIndividualClientOrDirector && selectedExistingClientData
            ? selectedExistingClientData?.surName
            : '',
        preferredName:
          isSelectedIndividualClientOrDirector && selectedExistingClientData
            ? selectedExistingClientData?.preferredName
            : '',
        gender:
          isSelectedIndividualClientOrDirector && selectedExistingClientData
            ? selectedExistingClientData?.gender
            : '',
        dateOfBirth:
          isSelectedIndividualClientOrDirector && selectedExistingClientData?.dateOfBirth
            ? new Date(selectedExistingClientData?.dateOfBirth)
            : null,
        age:
          isSelectedIndividualClientOrDirector &&
          selectedExistingClientData &&
          selectedExistingClientData?.dateOfBirth
            ? getAgeFromDOB(
                selectedExistingClientData?.dateOfBirth &&
                  new Date(selectedExistingClientData?.dateOfBirth),
                true
              )
            : '',
        // lifeExpectancy: selectedExistingClientData?.lifeExpectancy || '',
        maritalStatus:
          isSelectedIndividualClientOrDirector && selectedExistingClientData
            ? selectedExistingClientData?.maritalStatus
            : '',
        hasPartner:
          isSelectedIndividualClientOrDirector && selectedExistingClientData
            ? selectedExistingClientData?.hasPartner
            : false,
        // idType: selectedExistingClientData?.idType || '',
        // idNumber: selectedExistingClientData?.idNumber || '',
        occupationStatus:
          isSelectedIndividualClientOrDirector && selectedExistingClientData
            ? selectedExistingClientData?.occupationStatus
            : '',
        occupationStartedDate:
          isSelectedIndividualClientOrDirector && selectedExistingClientData?.occupationStartedDate
            ? new Date(selectedExistingClientData?.occupationStartedDate)
            : null,
        occupation:
          isSelectedIndividualClientOrDirector && selectedExistingClientData
            ? selectedExistingClientData?.occupation
            : '',
        employerName:
          isSelectedIndividualClientOrDirector && selectedExistingClientData
            ? selectedExistingClientData?.employerName
            : '',
        employmentStatus:
          isSelectedIndividualClientOrDirector && selectedExistingClientData
            ? selectedExistingClientData?.employmentStatus
            : '',
        employmentType:
          isSelectedIndividualClientOrDirector && selectedExistingClientData
            ? selectedExistingClientData?.employmentType
            : '',
        hoursWorked:
          isSelectedIndividualClientOrDirector && selectedExistingClientData
            ? selectedExistingClientData?.hoursWorked
            : '',
        yearsInCurrentOccupation:
          isSelectedIndividualClientOrDirector && selectedExistingClientData
            ? selectedExistingClientData?.yearsInCurrentOccupation
            : '',
        desiredRetirementAge:
          isSelectedIndividualClientOrDirector && selectedExistingClientData
            ? selectedExistingClientData?.desiredRetirementAge
            : '',

        primaryEmailAddress:
          isSelectedIndividualClientOrDirector && selectedExistingClientData
            ? selectedExistingClientData?.primaryEmailAddress
            : '',
        homePhoneNumber:
          isSelectedIndividualClientOrDirector && selectedExistingClientData
            ? selectedExistingClientData?.homePhoneNumber
            : '',
        primaryMobileNumber:
          isSelectedIndividualClientOrDirector && selectedExistingClientData
            ? selectedExistingClientData?.primaryMobileNumber
            : '',
        hasPrivateHealthCover:
          isSelectedIndividualClientOrDirector && selectedExistingClientData
            ? selectedExistingClientData?.hasPrivateHealthCover
            : false,
        smoker:
          isSelectedIndividualClientOrDirector && selectedExistingClientData
            ? selectedExistingClientData?.smoker
            : false,
        emailWeeklyTaskSummary:
          isSelectedIndividualClientOrDirector && selectedExistingClientData
            ? selectedExistingClientData?.emailWeeklyTaskSummary
            : false,
        taxFileNumber:
          isSelectedIndividualClientOrDirector && selectedExistingClientData
            ? selectedExistingClientData?.taxFileNumber
            : '',
        clientType:
          isSelectedIndividualClientOrDirector && selectedExistingClientData
            ? selectedExistingClientData?.clientType
            : 'Client',
      };
      const selectedOtherData = {
        name:
          isExistingClient &&
          (shareholderExistingType !== 'Individual' || shareholderExistingType !== 'Director') &&
          selectedExistingClientData?.name
            ? selectedExistingClientData?.name
            : '',
        abn:
          isExistingClient &&
          (shareholderExistingType !== 'Individual' || shareholderExistingType !== 'Director') &&
          selectedExistingClientData?.abn
            ? selectedExistingClientData?.abn
            : '',
        tfn:
          isExistingClient &&
          (shareholderExistingType !== 'Individual' || shareholderExistingType !== 'Director') &&
          selectedExistingClientData?.tfn
            ? selectedExistingClientData?.tfn
            : '',
        established:
          isExistingClient &&
          (shareholderExistingType !== 'Individual' || shareholderExistingType !== 'Director') &&
          selectedExistingClientData?.established
            ? new Date(selectedExistingClientData?.established)
            : '',
      };
      formikContext?.setValues &&
        formikContext.setValues({
          ...(formikContext?.initialValues || {}),
          isExistingClient: true,
          shareholderExistingType: shareholderExistingType,
          ...idKeyValueData,
          ...selectedIndividualClientData,
          ...selectedOtherData,
        });
    } else if (!isExistingClient && editData?._id) {
      formikContext?.setValues &&
        formikContext.setValues({
          ...(formikContext?.initialValues || {}),
          isExistingClient: false,
          clientId: null,

          title: editData?._id ? editData?.title : '',
          firstName: editData?._id ? editData?.firstName : '',
          middleName: editData?._id ? editData?.middleName : '',
          surName: editData?._id ? editData?.surName : '',
          preferredName: editData?._id ? editData?.preferredName : '',
          gender: editData?._id ? editData?.gender : '',
          dateOfBirth: editData?.dateOfBirth ? new Date(editData?.dateOfBirth) : null,
          age: editData?.dateOfBirth
            ? getAgeFromDOB(editData?.dateOfBirth && new Date(editData?.dateOfBirth), true)
            : '',
          // lifeExpectancy: editData?.lifeExpectancy || '',
          maritalStatus: editData?._id ? editData?.maritalStatus : '',
          hasPartner: editData?._id ? editData?.hasPartner : false,
          // idType: editData?.idType || '',
          // idNumber: editData?.idNumber || '',
          occupationStatus: editData?._id ? editData?.occupationStatus : '',
          occupationStartedDate: editData?.occupationStartedDate
            ? new Date(editData?.occupationStartedDate)
            : null,
          occupation: editData?._id ? editData?.occupation : '',
          employerName: editData?._id ? editData?.employerName : '',
          employmentStatus: editData?._id ? editData?.employmentStatus : '',
          employmentType: editData?._id ? editData?.employmentType : '',
          hoursWorked: editData?._id ? editData?.hoursWorked : '',
          yearsInCurrentOccupation: editData?._id ? editData?.yearsInCurrentOccupation : '',
          desiredRetirementAge: editData?._id ? editData?.desiredRetirementAge : '',

          primaryEmailAddress: editData?._id ? editData?.primaryEmailAddress : '',
          homePhoneNumber: editData?._id ? editData?.homePhoneNumber : '',
          primaryMobileNumber: editData?._id ? editData?.primaryMobileNumber : '',
          hasPrivateHealthCover: editData?._id ? editData?.hasPrivateHealthCover : false,
          smoker: editData?._id ? editData?.smoker : false,
          emailWeeklyTaskSummary: editData?._id ? editData?.emailWeeklyTaskSummary : false,
          taxFileNumber: editData?._id ? editData?.taxFileNumber : '',
          clientType: editData?._id ? editData?.clientType : 'Client',
        });
    }
  };
  //data key
  const shareholderDataKey = editData?.directorId?._id
    ? 'directorId'
    : (editData?.clientId?._id || !editData?.isExistingClient
        ? 'clientId'
        : editData?.smsfId?._id
        ? 'smsfId'
        : editData?.companyId?._id
        ? 'companyId'
        : editData?.trustId?._id && 'trustId') || 'N/A';
  /**
   * Get company name
   * @param {*} companyDetail
   * @returns companyName
   */
  const getCompanyName = () => {
    return editData?.[shareholderDataKey]?.name;
  };
  /**
   * Auto select client field on mount with edit values
   */
  const handleFindOtherOptionsDetails = () => {
    setTimeout(() => {
      const shareholderData = editData?.[shareholderDataKey];
      setSelectedExistingClientData && setSelectedExistingClientData(shareholderData);

      formikContext?.setFieldValue &&
        formikContext.setFieldValue(shareholderDataKey, shareholderData?._id);
      formikContext?.setFieldTouched &&
        formikContext.setFieldTouched(shareholderDataKey, true, true);
    }, 100);
  };
  /**
   * Handle initialize shareholder edit data
   */
  const handleInitializeShareholderEditModeData = () => {
    if (editData?._id) {
      const isExistingClient =
        editData?.isExistingClient &&
        (editData?.directorId?._id ||
          editData?.clientId?._id ||
          editData?.smsfId?._id ||
          editData?.companyId?._id ||
          editData?.trustId?._id);
      if (isExistingClient) {
        const clientOrDirectorKey = editData?.clientId?._id
          ? 'clientId'
          : editData?.directorId?._id
          ? 'directorId'
          : null;
        const clientName =
          clientOrDirectorKey && editData?.[clientOrDirectorKey]?._id
            ? (editData?.[clientOrDirectorKey]?.title &&
                editData?.[clientOrDirectorKey].title +
                  ' ' +
                  ((editData?.[clientOrDirectorKey]?.surName &&
                    editData?.[clientOrDirectorKey]?.firstName +
                      ' ' +
                      editData?.[clientOrDirectorKey].surName) ||
                    editData?.[clientOrDirectorKey].firstName)) ||
              (editData?.[clientOrDirectorKey]?.surName &&
                editData?.[clientOrDirectorKey]?.firstName +
                  ' ' +
                  editData?.[clientOrDirectorKey]?.surName) ||
              editData?.clientId?.firstName
            : getCompanyName();
        setClientOptions &&
          setClientOptions((prev) => [
            {
              id:
                editData?.directorId?._id ||
                editData?.clientId?._id ||
                editData?.smsfId?._id ||
                editData?.companyId?._id ||
                editData?.trustId?._id,
              value:
                editData?.directorId?._id ||
                editData?.clientId?._id ||
                editData?.smsfId?._id ||
                editData?.companyId?._id ||
                editData?.trustId?._id,
              label: clientName,
              name: clientName,
            },
          ]);
        setIsExistingClient && setIsExistingClient(true);
        if (editData?.clientId?._id) {
          handleFindClientDetails &&
            handleFindClientDetails(editData?.clientId?._id, true, formikContext);
        } else {
          handleFindOtherOptionsDetails();
        }
      }
    }
  };
  /**
   * Effect on existing client selection
   */
  useEffect(() => {
    if (isShareholder) {
      handleInitializeShareholderData();
    } else {
      handleInitializeData();
    }
  }, [selectedExistingClientData, isExistingClient]);
  /**
   * Effect on mount
   */
  useEffect(() => {
    if (isShareholder) {
      handleInitializeShareholderEditModeData();
    } else {
      handleInitializeEditModeData();
    }
  }, [editData]);
  return null;
};

export default EditInitialDataFormik;
