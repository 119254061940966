import { isEmpty, isNil } from 'ramda';

/**
 * Get Dynamic Base from ENV
 * @returns {object} {Base URL, Current Environment}
 */
const handleGetBaseAndEnv = (customModule = null) => {
  let baseURL,
    currentEnv,
    currentPortal = null;
  //* DEFAULT ADVISOR_PORTAL BASE PATHS
  let DEV_BASE = 'https://lf4g5xr70b.execute-api.ap-southeast-2.amazonaws.com/';
  let QA_BASE = 'https://olv31xba27.execute-api.ap-southeast-2.amazonaws.com/';
  let UAT_BASE = 'https://c3h7ys0bea.execute-api.ap-southeast-2.amazonaws.com/';
  let LOCAL_BASE = 'http://localhost:4005/';
  let PROD_BASE = 'https://4sx6aiqch6.execute-api.ap-southeast-2.amazonaws.com/';

  let nodeProcessCurrentEnv = process.env?.['REACT_APP_ENV'];
  let nodeProcessCurrentPortal = process.env?.['REACT_APP_PORTAL'];

  currentEnv = nodeProcessCurrentEnv || 'dev';
  currentPortal = nodeProcessCurrentPortal || '';

  if (currentEnv === 'dev') {
    baseURL = DEV_BASE;
    if (!isNil(customModule) && !isEmpty(customModule)) {
      if (customModule === 'UTILS') {
        baseURL = 'https://6gg78zl6xh.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'AUTH') {
        baseURL = 'https://r9adqr1ii7.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'PAYMENTS') {
        baseURL = 'https://lf4g5xr70b.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'ADMIN_PORTAL') {
        baseURL = 'https://35m5yggfqc.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'SETTINGS') {
        baseURL = 'https://4uxjknbwd9.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'SMSF') {
        baseURL = 'https://xprzs25820.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'KANBAN') {
        baseURL = 'https://jpzxtj2w59.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'CLIENT_PORTAL') {
        baseURL = 'https://aw95h1vvc5.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'TRUST') {
        baseURL = 'https://xprzs25820.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'COMPANY') {
        baseURL = 'https://v3my3ven8c.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'PARTNERSHIP') {
        baseURL = 'https://c19z9j2dkg.execute-api.ap-southeast-2.amazonaws.com/';
      }
    }
  } else if (currentEnv === 'qa') {
    baseURL = QA_BASE;
    if (!isNil(customModule) && !isEmpty(customModule)) {
      if (customModule === 'UTILS') {
        baseURL = 'https://oc8jqvm6rh.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'AUTH') {
        baseURL = 'https://3t64hxdis2.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'PAYMENTS') {
        baseURL = 'https://qrsu7plbnl.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'ADMIN_PORTAL') {
        baseURL = 'https://uw2mar3mi0.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'SETTINGS') {
        baseURL = 'https://gf8cs1dfef.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'SMSF') {
        baseURL = 'https://qftlp3qjob.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'KANBAN') {
        baseURL = 'https://fkzi5q0wbj.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'CLIENT_PORTAL') {
        baseURL = 'https://afriywlf67.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'TRUST') {
        baseURL = 'https://6f3f7rmaqc.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'COMPANY') {
        baseURL = 'https://eukuqn64ha.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'PARTNERSHIP') {
        baseURL = 'https://s7vtwjrk64.execute-api.ap-southeast-2.amazonaws.com/';
      }
    }
  } else if (currentEnv === 'uat') {
    baseURL = UAT_BASE;
    if (!isNil(customModule) && !isEmpty(customModule)) {
      if (customModule === 'UTILS') {
        baseURL = 'https://plfen6k3ij.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'AUTH') {
        baseURL = 'https://n1f9ojvem8.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'PAYMENTS') {
        baseURL = 'https://xzauwyu9gd.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'ADMIN_PORTAL') {
        baseURL = 'https://9echz69v12.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'SETTINGS') {
        baseURL = 'https://mpyn6nhfpf.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'SMSF') {
        baseURL = 'https://j09qrb6yj9.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'KANBAN') {
        baseURL = 'https://xkx44x1m0b.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'CLIENT_PORTAL') {
        baseURL = 'https://75h9zuriz7.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'TRUST') {
        baseURL = 'https://mf3zrcstmb.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'COMPANY') {
        baseURL = 'https://gyedmbvlxd.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'PARTNERSHIP') {
        baseURL = 'https://pd7ma49z3i.execute-api.ap-southeast-2.amazonaws.com/';
      }
    }
  } else if (currentEnv === 'prod') {
    baseURL = PROD_BASE;
    if (!isNil(customModule) && !isEmpty(customModule)) {
      if (customModule === 'UTILS') {
        baseURL = 'https://k43y8ljsf1.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'AUTH') {
        baseURL = 'https://8axoexlcpd.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'PAYMENTS') {
        baseURL = 'https://e0ihwc9755.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'ADMIN_PORTAL') {
        baseURL = 'https://byhglthinj.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'SETTINGS') {
        baseURL = 'https://f981rsuilb.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'SMSF') {
        baseURL = 'https://ddiplxum8f.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'KANBAN') {
        baseURL = 'https://63duoc1d1a.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'CLIENT_PORTAL') {
        baseURL = 'https://20di3qmlcg.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'TRUST') {
        baseURL = 'https://hwvjycqndf.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'COMPANY') {
        baseURL = 'https://tfz8hwngta.execute-api.ap-southeast-2.amazonaws.com/';
      } else if (customModule === 'PARTNERSHIP') {
        baseURL = 'https://frvvpdzqq4.execute-api.ap-southeast-2.amazonaws.com/';
      }
    }
  } else if (currentEnv === 'local') {
    baseURL = LOCAL_BASE; //4005 httpPort
    if (!isNil(customModule) && !isEmpty(customModule)) {
      if (customModule === 'UTILS') {
        baseURL = 'http://localhost:4001/';
      } else if (customModule === 'AUTH') {
        baseURL = 'http://localhost:4002/';
      } else if (customModule === 'PAYMENTS') {
        baseURL = 'http://localhost:4003/';
      } else if (customModule === 'ADMIN_PORTAL') {
        baseURL = 'http://localhost:4004/';
      } else if (customModule === 'SETTINGS') {
        baseURL = 'http://localhost:4006/';
      } else if (customModule === 'SMSF') {
        baseURL = 'http://localhost:4007/';
      } else if (customModule === 'KANBAN') {
        baseURL = 'http://localhost:4008/';
      } else if (customModule === 'CLIENT_PORTAL') {
        baseURL = 'http://localhost:4009/';
      } else if (customModule === 'TRUST') {
        baseURL = 'http://localhost:4010/';
      } else if (customModule === 'COMPANY') {
        baseURL = 'http://localhost:4011/';
      } else if (customModule === 'PARTNERSHIP') {
        baseURL = 'http://localhost:4012/';
      }
    }
  }

  return { baseURL, currentEnv, currentPortal };
};

const { baseURL, currentEnv, currentPortal } = handleGetBaseAndEnv();

const basePath = baseURL + currentEnv;

//module base paths
const AuthModuleBasePath = `${handleGetBaseAndEnv('AUTH')?.baseURL}${
  handleGetBaseAndEnv('AUTH')?.currentEnv
}`;
const SMSFModuleBasePath = `${handleGetBaseAndEnv('SMSF')?.baseURL}${
  handleGetBaseAndEnv('SMSF')?.currentEnv
}`;
const KanbanModuleBasePath = `${handleGetBaseAndEnv('KANBAN')?.baseURL}${
  handleGetBaseAndEnv('KANBAN')?.currentEnv
}`;
const UtilsModuleBasePath = `${handleGetBaseAndEnv('UTILS')?.baseURL}${
  handleGetBaseAndEnv('UTILS')?.currentEnv
}`;
const SettingsModuleBasePath = `${handleGetBaseAndEnv('SETTINGS')?.baseURL}${
  handleGetBaseAndEnv('SETTINGS')?.currentEnv
}`;
const PaymentsModuleBasePath = `${handleGetBaseAndEnv('PAYMENTS')?.baseURL}${
  handleGetBaseAndEnv('PAYMENTS')?.currentEnv
}`;
const ClientModuleBasePath = `${handleGetBaseAndEnv('CLIENT_PORTAL')?.baseURL}${
  handleGetBaseAndEnv('CLIENT_PORTAL')?.currentEnv
}`;
const TrustModuleBasePath = `${handleGetBaseAndEnv('TRUST')?.baseURL}${
  handleGetBaseAndEnv('TRUST')?.currentEnv
}`;
const CompanyModuleBasePath = `${handleGetBaseAndEnv('COMPANY')?.baseURL}${
  handleGetBaseAndEnv('COMPANY')?.currentEnv
}`;

const PartnershipModuleBasePath = `${handleGetBaseAndEnv('PARTNERSHIP')?.baseURL}${
  handleGetBaseAndEnv('PARTNERSHIP')?.currentEnv
}`;

const FileBrowserBasePath = `https://fileuploadnew1.azurewebsites.net/api/AmazonS3Provider`;

const API_URL = {
  baseURL,
  basePath,
  currentEnv,
  currentPortal,
  auth: {
    moduleVariable: 'AUTH',
    moduleBasePath: AuthModuleBasePath,
    authentication: {
      POST_SIGN_UP: AuthModuleBasePath + '/signup',
      POST_SIGN_IN: AuthModuleBasePath + '/login',

      POST_SEND_PASSWORD_RESET_LINK: AuthModuleBasePath + '/password-reset',
      POST_RESET_USER_PASSWORD: AuthModuleBasePath + '/password-reset/{userId}/{requestToken}',
      POST_VERIFY_USER_EMAIL: AuthModuleBasePath + '/user/verify/{userId}/{requestToken}',
    },
    registration: {
      POST_CREATE_ADVISOR: AuthModuleBasePath + '/advisors',
    },
    advisorProfiles: {
      PUT_CHANGE_MY_PROFILE_PASSWORD: AuthModuleBasePath + '/update-password',
    },
    signOut: {
      GET_SIGN_OUT: AuthModuleBasePath + '/logout',
    },
  },
  client: {
    hello: basePath + '/hello',
    clientProfile: {
      PATCH_UPDATE_CLIENT_PROFILE_BY_ID: basePath + '/clients/{clientId}',
      GET_FIND_CLIENT_BY_CLIENT_ID: basePath + '/clients/{clientId}',
      GET_FIND_CLIENT_STRENGTH_BY_CLIENT_ID: basePath + '/clients/client-strength/{clientId}',
      GET_FIND_CLIENT_BY_CLIENT_ID_FOR_ENTITIES: basePath + '/find-clients-entities/{clientId}',
    },
    clientList: {
      GET_ALL_CLIENTS: basePath + '/clients',
      POST_CREATE_NEW_CLIENT: basePath + '/clients',
      POST_CREATE_NEW_MULTIPLE_CLIENTS: basePath + '/multipleClients',
      POST_FIND_FILTERED_CLIENTS: basePath + '/clients/filter',
      POST_FILTERED_PRIMARY_CLIENTS: basePath + '/primary-clients/filter',
    },
    clientInvitation: {
      POST_INVITE_CLIENT: basePath + '/clients/invites',
      GET_CLIENT_INVITATION_TEMPLATE: basePath + '/client-documents/invite',
    },
    clientDocuments: {
      POST_SAVE_CLIENT_DOCUMENT: basePath + '/client-documents',
      GET_CLIENT_DOCUMENTS: basePath + '/client-documents/client/{clientId}',
      PATCH_UPDATE_CLIENT_DOCUMENT: basePath + '/client-documents/document/{documentId}',
      DELETE_CLIENT_DOCUMENT: basePath + '/client-documents/{documentId}',
    },
    financial: {
      moduleVariable: 'CLIENT_PORTAL',
      moduleBasePath: ClientModuleBasePath,
      financialPosition: {
        CREATE: ClientModuleBasePath + '/client/financial/createFinancialPosition/{clientId}',
        PATCH_SAVE_FINANCIAL_POSITION:
          ClientModuleBasePath + '/client/financial/createFinancialPosition/{clientId}',
        PATCH_CHANGE_ACTIVE_FINANCIAL_POSITION:
          ClientModuleBasePath + '/client/financial/switchFinancialPosition/clientId/{clientId}',
        PATCH_UPDATE_FINANCIAL_POSITION_BY_ID:
          ClientModuleBasePath + '/client/financial/updateFinancialPosition/{financialPositionId}',
        GET_FINANCIAL_POSITION:
          ClientModuleBasePath + '/client/financial/getFinancialPosition/client/{clientId}',
        GET_FINANCIAL_POSITION_SUMMARY:
          ClientModuleBasePath +
          '/client/financial/getActiveFinancialPositionSummary/financialPosition/{financialPositionId}',
      },
      expense: {
        CREATE:
          ClientModuleBasePath +
          '/client/financial/createExpenses/{clientId}/{financialPositionId}',
        UPDATE:
          ClientModuleBasePath +
          '/client/financial/updateExpenses/{clientId}/{financialPositionId}/{clientExpenseId}/{expensesId}',
        DELETE:
          ClientModuleBasePath +
          '/client/financial/deleteExpenses/{clientId}/{financialPositionId}/{clientExpenseId}/{expensesId}',
        GET:
          ClientModuleBasePath + '/client/financial/getExpenses/{clientId}/{financialPositionId}',
      },
      liability: {
        CREATE:
          ClientModuleBasePath +
          '/client/financial/createLiability/{clientId}/{financialPositionId}',
        UPDATE:
          ClientModuleBasePath +
          '/client/financial/updateLiability/{clientId}/{financialPositionId}/{clientLiabilityId}/{liabilityId}',
        DELETE:
          ClientModuleBasePath +
          '/client/financial/deleteLiability/{clientId}/{financialPositionId}/{clientLiabilityId}/{liabilityId}',
        GET:
          ClientModuleBasePath + '/client/financial/getLiability/{clientId}/{financialPositionId}',
      },
      asset: {
        CREATE:
          ClientModuleBasePath + '/client/financial/createAsset/{clientId}/{financialPositionId}',
        UPDATE:
          ClientModuleBasePath +
          '/client/financial/updateAsset/{clientId}/{financialPositionId}/{clientAssetId}/{assetId}',
        DELETE:
          ClientModuleBasePath +
          '/client/financial/deleteAsset/{clientId}/{financialPositionId}/{clientAssetId}/{assetId}',
        GET: ClientModuleBasePath + '/client/financial/getAsset/{clientId}/{financialPositionId}',
      },
      income: {
        CREATE:
          ClientModuleBasePath + '/client/financial/createIncome/{clientId}/{financialPositionId}',
        UPDATE:
          ClientModuleBasePath +
          '/client/financial/updateIncome/{clientId}/{financialPositionId}/{clientIncomeId}/{incomeId}',
        DELETE:
          ClientModuleBasePath +
          '/client/financial/deleteIncome/{clientId}/{financialPositionId}/{clientIncomeId}/{incomeId}',
        GET: ClientModuleBasePath + '/client/financial/getIncome/{clientId}/{financialPositionId}',
      },
      financialProtections: {
        GET_ALL_SUPERANNUATION_FUND_OWNERS:
          ClientModuleBasePath +
          '/client/financial/getSuperannuationFundAsset/client/{clientId}/financialPosition/{financialPositionId}',
      },
      familyAndLifestyleProtection: {
        POST_SAVE_FAMILY_AND_LIFESTYLE_PROTECTION:
          ClientModuleBasePath + '/client/createInsurance/financialPosition/{financialPositionId}',
        GET_ALL_FAMILY_AND_LIFESTYLE_PROTECTIONS_BY_CLIENT_ID:
          ClientModuleBasePath +
          '/client/getAllInsurance/client/{clientId}/financialPosition/{financialPositionId}',
        //todo
        GET_INSURANCE_PROTECTION_DETAIL_BY_PROTECTION_DETAIL_ID:
          ClientModuleBasePath + '/client/getInsurance/insurance/{insuranceDetailId}',
        PATCH_UPDATE_INSURANCE_PROTECTION_DETAIL:
          ClientModuleBasePath +
          '/client/updateInsurance/financialPosition/{financialPositionId}/insurance/{insuranceDetailId}',
        DELETE_FAMILY_AND_LIFESTYLE_PROTECTION_BY_INSURANCE_DETAIL_ID:
          ClientModuleBasePath +
          '/client/deleteInsurance/financialPosition/{financialPositionId}/insurance/{insuranceDetailId}',
      },
    },
    clientQuestionnaire: {
      GET_CLIENT_QUESTIONNAIRES: basePath + '/questionnaire/{questionnaireId}',
      PUT_CREATE_OR_UPDATE_CLIENT_QUESTIONNAIRE:
        basePath + '/createClientQuestionnaires/questionnaires/{clientId}', // all questionnaires with answers is submitted
      DELETE_CLIENT_QUESTIONNAIRE:
        basePath + '/deleteQuestionnaires/questionnaires/{questionnaireDataId}',

      POST_FILTER_CLIENT_QUESTIONNAIRES: basePath + '/questionnaires/filter',
      PATCH_UPDATE_QUESTIONNAIRE: basePath + '/questionnaire/update/{questionnaireId}',

      GET_STATUS_QUESTIONNAIRE: basePath + '/getQuestionnairesStatus/client/{clientId}',
    },
  },
  SMSF: {
    moduleVariable: 'SMSF',
    moduleBasePath: SMSFModuleBasePath,
    smsfProfile: {
      POST_SAVE_SMSF_CLIENT: SMSFModuleBasePath + '/smsf/createSmsf',
      PATCH_UPDATE_SMSF_CLIENT_BY_ID:
        SMSFModuleBasePath + '/smsf/updateSmsfById/smsf/{SMSFClientId}',
      GET_FIND_SMSF_CLIENT_BY_ID: SMSFModuleBasePath + '/smsf/getByIdSmsf/smsf/{SMSFClientId}',
      POST_CREATE_NEW_SMSF_MEMBER_ON_UPDATE:
        SMSFModuleBasePath + '/smsf/createMember/smsf/{SMSFClientId}',
      PATCH_UPDATE_SMSF_MEMBER_BY_MEMBER_ID:
        SMSFModuleBasePath + '/smsf/updateMember/smsf/{SMSFClientId}/member/{memberId}',
      DELETE_SMSF_MEMBER:
        SMSFModuleBasePath + '/smsf/deleteMember/smsf/{SMSFClientId}/member/{memberId}',
      GET_SMSF_CLIENT_DASHBOARD_STATS_BY_FINANCIAL_POSITION:
        SMSFModuleBasePath +
        '/smsf/smsfFinancial/getSmsfFinancialPosition/smsfFinancialPosition/{activeFinancialPositionId}',

      POST_SAVE_MULTIPLE_SMSF_CLIENT: SMSFModuleBasePath + '/smsf/createSmsfsCSV',
    },
    smsfClientList: {
      GET_ALL_SMSF_CLIENTS: SMSFModuleBasePath + '/smsf/getAllSmsf',
      FILTER_SMSF: SMSFModuleBasePath + '/smsf/filter',
      ADD_LINKED_ENTITIES: SMSFModuleBasePath + '/smsf/add-linked-entities',
      DELETE_LINKED_ENTITIES: SMSFModuleBasePath + '/smsf/delete-linked-entities',
    },

    individualTrustee: {
      POST_CREATE_SMSF_INDIVIDUAL: SMSFModuleBasePath + '/smsf/createIndividual/smsf/{smsfId}',
      DELETE_SMSF_INDIVIDUAL:
        SMSFModuleBasePath + '/smsf/deleteIndividual/smsf/{smsfId}/individual/{individualId}',
    },
    corporateTrustee: {
      POST_CREATE_CORPORATE_SMSF_DIRECTOR:
        SMSFModuleBasePath + '/smsf/createDirector/smsf/{smsfId}',
      DELETE_CORPORATE_SMSF_DIRECTOR:
        SMSFModuleBasePath + '/smsf/deleteDirector/smsf/{smsfId}/director/{directorId}',

      POST_CREATE_CORPORATE_SMSF_SHAREHOLDER:
        SMSFModuleBasePath + '/smsf/createShareholder/smsf/{smsfId}',
      DELETE_CORPORATE_SMSF_SHAREHOLDER:
        SMSFModuleBasePath + '/smsf/deleteShareholder/smsf/{smsfId}/shareholder/{shareholderId}',
    },

    financial: {
      financialPosition: {
        POST_CREATE_SMSF_FINANCIAL_POSITION:
          SMSFModuleBasePath + '/smsf/smsfFinancial/createSmsfFinancialPosition/smsf/{smsfId}',
        PATCH_CHANGE_ACTIVE_SMSF_FINANCIAL_POSITION:
          SMSFModuleBasePath + '/smsf/smsfFinancial/switchSmsfFinancialPosition/smsf/{smsfId}',
        GET_ALL_SMSF_FINANCIAL_POSITIONS:
          SMSFModuleBasePath + '/smsf/smsfFinancial/getAllSmsfFinancialPosition/smsf/{smsfId}',
        GET_FIND_SMSF_FINANCIAL_POSITION_DETAIL_BY_ID:
          SMSFModuleBasePath +
          '/smsf/smsfFinancial/getSmsfFinancialPosition/smsfFinancialPosition/{activeFinancialPositionId}',
      },

      asset: {
        CREATE:
          SMSFModuleBasePath + '/smsf/createAsset/smsfFinancialPosition/{financialPositionId}',
        UPDATE:
          SMSFModuleBasePath +
          '/smsf/updateAsset/smsfFinancialPosition/{financialPositionId}/asset/{assetId}',
        DELETE:
          SMSFModuleBasePath +
          '/smsf/deleteAsset/smsfFinancialPosition/{financialPositionId}/asset/{assetId}',
        GET: SMSFModuleBasePath + '/smsf/getSmsfAsset/smsfFinancialPosition/{financialPositionId}',
      },

      income: {
        CREATE:
          SMSFModuleBasePath + '/smsf/createIncome/smsfFinancialPosition/{financialPositionId}',
        UPDATE:
          SMSFModuleBasePath +
          '/smsf/updateIncome/smsfFinancialPosition/{financialPositionId}/income/{incomeId}',
        DELETE:
          SMSFModuleBasePath +
          '/smsf/deleteIncome/smsfFinancialPosition/{financialPositionId}/income/{incomeId}',
        GET: SMSFModuleBasePath + '/smsf/getSmsfIncome/smsfFinancialPosition/{financialPositionId}',
      },
      liability: {
        CREATE:
          SMSFModuleBasePath + '/smsf/createLiability/smsfFinancialPosition/{financialPositionId}',
        UPDATE:
          SMSFModuleBasePath +
          '/smsf/updateLiability/smsfFinancialPosition/{financialPositionId}/liability/{liabilityId}',
        DELETE:
          SMSFModuleBasePath +
          '/smsf/deleteLiability/smsfFinancialPosition/{financialPositionId}/liability/{liabilityId}',
        GET:
          SMSFModuleBasePath + '/smsf/getSmsfLiability/smsfFinancialPosition/{financialPositionId}',
      },
      expense: {
        CREATE:
          SMSFModuleBasePath + '/smsf/createExpense/smsfFinancialPosition/{financialPositionId}',
        UPDATE:
          SMSFModuleBasePath +
          '/smsf/updateExpense/smsfFinancialPosition/{financialPositionId}/expense/{expenseId}',
        DELETE:
          SMSFModuleBasePath +
          '/smsf/deleteExpense/smsfFinancialPosition/{financialPositionId}/expense/{expenseId}',
        GET:
          SMSFModuleBasePath + '/smsf/getSmsfExpense/smsfFinancialPosition/{financialPositionId}',
      },

      contribution: {
        CREATE:
          SMSFModuleBasePath +
          '/smsf/createContribution/smsfFinancialPosition/{financialPositionId}',
        UPDATE:
          SMSFModuleBasePath +
          '/smsf/updateContribution/smsfFinancialPosition/{financialPositionId}/contribution/{contributionId}',
        DELETE:
          SMSFModuleBasePath +
          '/smsf/deleteContribution/smsfFinancialPosition/{financialPositionId}/contribution/{contributionId}',
        GET:
          SMSFModuleBasePath +
          '/smsf/getSmsfContribution/smsfFinancialPosition/{financialPositionId}',
      },

      cashflow: {
        CREATE:
          SMSFModuleBasePath + '/smsf/createCashflow/smsfFinancialPosition/{financialPositionId}',
        UPDATE:
          SMSFModuleBasePath +
          '/smsf/updateCashflow/smsfFinancialPosition/{financialPositionId}/cashflow/{cashflowId}',
        DELETE:
          SMSFModuleBasePath +
          '/smsf/deleteCashflow/smsfFinancialPosition/{financialPositionId}/cashflow/{cashflowId}',
        GET:
          SMSFModuleBasePath + '/smsf/getSmsfCashflow/smsfFinancialPosition/{financialPositionId}',
      },
    },
  },
  lead: {
    leadProfile: {
      GET_FIND_LEAD_BY_ID: basePath + '/leads/{leadId}',
      PUT_UPDATE_LEAD: basePath + '/leads/{leadId}',
      DELETE_LEAD_BY_LEAD_ID: basePath + '/leads/{leadId}',
    },
    leadList: {
      GET_ALL_LEADS: basePath + '/leads',
      POST_SAVE_LEAD: basePath + '/leads',
      POST_SEARCH_LEADS_BY_FILTERS: basePath + '/leads/filter',
    },
    leadQuestionnaire: {
      GET_LEAD_QUESTIONNAIRES: basePath + '/questionnaire/{questionnaireId}',
      PATCH_UPDATE_LEAD_QUESTIONNAIRE: basePath + '/questionnaire/update/{questionnaireDataId}', // all questionnaires with answers is submitted
      DELETE_QUESTIONNAIRE_QUESTION_BY_ID:
        basePath +
        '/deleteQuestionnaires/questionnaires/{questionnaireDataId}/question/{questionId}',
    },
  },
  files: {
    moduleVariable: 'UTILS',
    moduleBasePath: UtilsModuleBasePath,
    POST_GENERATE_SIGNED_URL: UtilsModuleBasePath + '/files',
    //generated url is called for http put upload request
    GET_ALL_SENT_FILES: UtilsModuleBasePath + '/files/sent/{receiverId}',
    GET_ALL_RECEIVED_FILES: UtilsModuleBasePath + '/files/received/{senderId}',
    DELETE_FILE: UtilsModuleBasePath + '/files',
  },
  tasks: {
    POST_SAVE_TASKS: basePath + '/tasks',
    GET_ALL_TASKS: basePath + '/tasks',
    PUT_UPDATE_TASK: basePath + '/tasks/{taskId}',
    GET_FIND_TASK_BY_ID: basePath + '/tasks/{taskId}',
    DELETE_TASK_BY_TASK_ID: basePath + '/tasks/{taskId}',
    POST_SEARCH_TASKS_BY_FILTERS: basePath + '/tasks/filter',
    GET_ALL_TASK_ASSIGNEES: basePath + '/tasks/assignees',
  },
  composites: {
    moduleVariable: 'UTILS',
    moduleBasePath: UtilsModuleBasePath,
    GET_ALL_ENUMS: UtilsModuleBasePath + '/enums',
  },
  goals: {
    POST_SAVE_GOAL: basePath + '/clients/{clientId}/goals',
    PATCH_UPDATE_CLIENT_GOAL: basePath + '/clients/{clientId}/goals/{goalId}',
    GET_ALL_GOALS: basePath + '/clients/{clientId}/goals',
    DELETE_GOAL: basePath + '/clients/{clientId}/goals/{goalId}',
  },
  teamMembers: {
    POST_SAVE_TEAM_MEMBER: basePath + '/team-members',
    GET_ALL_TEAM_MEMBERS: basePath + '/team-members',
    GET_TEAM_MEMBER_FOR_ACCOUNT_OWNER_BY_ID: basePath + '/team-members/{accountOwnerId}',
    PUT_UPDATE_TEAM_MEMBER: basePath + '/team-members/{teamMemberId}',
    DELETE_TEAM_MEMBER_BY_ID: basePath + '/team-members/{teamMemberId}',
    POST_UPLOAD_TEAM_MEMBER_PROFILE_PIC: basePath + '/team-members/{teamMemberId}',
    POST_SEARCH_TEAM_MEMBERS_BY_FILTERS: basePath + '/team-members/filter',
  },
  email: {
    moduleVariable: 'UTILS',
    moduleBasePath: UtilsModuleBasePath,
    POST_SEND_EMAIL: UtilsModuleBasePath + '/emails',
    GET_SENT_EMAILS: UtilsModuleBasePath + '/emails/message', //format is /emails/{emailType}
    POST_BULK_SENT_EMAIL: UtilsModuleBasePath + '/emails/bulkEmail',
  },
  emailTemplates: {
    moduleVariable: 'UTILS',
    moduleBasePath: UtilsModuleBasePath,
    POST_CREATE_EMAIL_TEMPLATE: UtilsModuleBasePath + '/emailTemplates',
    GET_ALL_EMAIL_TEMPLATES: UtilsModuleBasePath + '/emailTemplates',
    POST_SEARCH_EMAIL_TEMPLATES: UtilsModuleBasePath + '/emailTemplates/filter',
    DELETE_EMAIL_TEMPLATE: UtilsModuleBasePath + '/emailTemplates/{emailTemplateId}',
    PUT_UPDATE_EMAIL_TEMPLATE: UtilsModuleBasePath + '/emailTemplates/{emailTemplateId}',
    // admin email templates
    GET_ALL_ADMIN_EMAIL_TEMPLATES: UtilsModuleBasePath + '/adminEmailTemplates',
  },
  investorProfile: {
    moduleVariable: 'SETTINGS',
    moduleBasePath: SettingsModuleBasePath,
    POST_CREATE_INVESTMENT_INVESTOR_PROFILE: SettingsModuleBasePath + '/settings/investorProfile',
    POST_CREATE_ASSET_CLASS_INVESTOR_PROFILE: SettingsModuleBasePath + '/settings/investorProfile',
    GET_INVESTOR_PROFILE: SettingsModuleBasePath + '/settings/investorProfile',
    PATCH_UPDATE_INVESTOR_PROFILE:
      SettingsModuleBasePath + '/settings/investorProfile/{investorProfileId}',
    DELETE_INVESTOR_PROFILE:
      SettingsModuleBasePath + '/settings/investorProfile/{investorProfileId}',
  },
  superannuationModelingSetting: {
    moduleVariable: 'SETTINGS',
    moduleBasePath: SettingsModuleBasePath,
    POST_CREATE_SUPERANNUATION_DEFAULT_SETTING:
      SettingsModuleBasePath + '/settings/superannuationModellingSettings',
    GET_SUPERANNUATION_DEFAULT_SETTING:
      SettingsModuleBasePath + '/settings/superannuationModellingSettings',
  },
  emailNotificationSetting: {
    moduleVariable: 'SETTINGS',
    moduleBasePath: SettingsModuleBasePath,
    POST_CREATE_EMAIL_NOTIFICATION_SETTINGS:
      SettingsModuleBasePath + '/settings/emailNotifications',
    GET_EMAIL_NOTIFICATION_SETTINGS: SettingsModuleBasePath + '/settings/emailNotifications',
    PATCH_UPDATE_EMAIL_NOTIFICATION_SETTINGS:
      SettingsModuleBasePath + '/settings/emailNotifications/{emailNotificationSettingId}',
  },
  insuranceModelingSetting: {
    moduleVariable: 'SETTINGS',
    moduleBasePath: SettingsModuleBasePath,
    POST_CREATE_INSURANCE_MODELING_SETTINGS:
      SettingsModuleBasePath + '/settings/insuranceModellingSettings',
    GET_INSURANCE_MODELING_SETTINGS:
      SettingsModuleBasePath + '/settings/insuranceModellingSettings',
    PATCH_UPDATE_INSURANCE_MODELING_SETTINGS:
      SettingsModuleBasePath + '/settings/insuranceModellingSettings/{insuranceModelingSettingsId}',
  },
  organizationSetting: {
    moduleVariable: 'SETTINGS',
    moduleBasePath: SettingsModuleBasePath,
    POST_CREATE_ORGANIZATION_SETTINGS: SettingsModuleBasePath + '/settings/organization',
    GET_ORGANIZATION_SETTINGS: SettingsModuleBasePath + '/settings/organization',
    PATCH_UPDATE_ORGANIZATION_SETTINGS:
      SettingsModuleBasePath + '/settings/organization/{organizationSettingsId}',
    PUT_UPLOAD_COMPANY_LOGO: '',
  },
  calculationSetting: {
    moduleVariable: 'SETTINGS',
    moduleBasePath: SettingsModuleBasePath,
    economicVariables: {
      POST_CREATE_ECONOMIC_VARIABLE_SETTING: SettingsModuleBasePath + '/settings/economicVariables',
      GET_ECONOMIC_VARIABLE_SETTINGS: SettingsModuleBasePath + '/settings/economicVariables',
      PATCH_UPDATE_ECONOMIC_VARIABLE_SETTINGS:
        SettingsModuleBasePath + '/settings/economicVariables/{economicVariablesSettingId}',
    },
  },
  clientAppSetting: {
    moduleVariable: 'SETTINGS',
    moduleBasePath: SettingsModuleBasePath,
    POST_CREATE_CLIENT_APP_SETTING: SettingsModuleBasePath + '/settings/clientApp',
    GET_CLIENT_APP_SETTING: SettingsModuleBasePath + '/settings/clientApp',
    PATCH_UPDATE_CLIENT_APP_SETTING:
      SettingsModuleBasePath + '/settings/clientApp/{clientAppSettingId}',
  },
  webFormSetting: {
    moduleVariable: 'SETTINGS',
    moduleBasePath: SettingsModuleBasePath,
    POST_CREATE_WEB_FORM_SETTING: SettingsModuleBasePath + '/settings/onboardingInformation',
    GET_WEB_FORM_SETTING: SettingsModuleBasePath + '/settings/onboardingInformation',
    PATCH_UPDATE_WEB_FORM_SETTING:
      SettingsModuleBasePath + '/settings/onboardingInformation/{onboardingInformationId}',
  },
  advisorProfiles: {
    GET_ADVISOR_PROFILE_DATA: basePath + '/profile',
    PUT_UPDATE_PROFILE: basePath + '/profile',
    POST_CREATE_PROFILE: basePath + '/profile',
  },
  fileNotes: {
    POST_SAVE_FILE_NOTE: basePath + '/client/fileNote/client/{clientId}',
    GET_ALL_FILE_NOTES: basePath + '/client/fileNote/client/{clientId}',
    PATCH_UPDATE_FILE_NOTE: basePath + '/client/fileNote/client/{clientId}/fileNote/{fileNoteId}',
    DELETE_FILE_NOTE: basePath + '/client/fileNote/client/{clientId}/fileNote/{fileNoteId}',
  },
  personIdentification: {
    UPDATE_PERSON_IDENTIFICATION_US_CITIZENSHIP:
      basePath + '/clients/{clientId}/identifications?person={personType}', //[individual, partner],
    GET_ALL_CLIENT_IDENTIFICATION: basePath + '/clients/{clientId}/identifications',
  },
  payments: {
    moduleVariable: 'PAYMENTS',
    moduleBasePath: PaymentsModuleBasePath,
    POST_SAVE_PAYMENT_INFORMATION: PaymentsModuleBasePath + '/payments',
    PUT_APPROVE_OR_REJECT_INCOMING_PAYMENT:
      PaymentsModuleBasePath + '/payments/{payerId}/{paymentId}',
    GET_ALL_PAYMENTS_OR_FILTERED_PAYMENTS:
      PaymentsModuleBasePath + '/payments?status={status}&payerId={payerId}&payeeId={payeeId}',
  },
  kanban: {
    moduleVariable: 'KANBAN',
    moduleBasePath: KanbanModuleBasePath,
    board: {
      CREATE_NEW_KANBAN_BOARD: KanbanModuleBasePath + '/kanban/createBoard',
      GET_KANBAN_BOARDS: KanbanModuleBasePath + '/kanban/getBoard',
      GET_KANBAN_BOARD_BY_ID: KanbanModuleBasePath + '/kanban/getBoard/board/{boardId}',
      GET_KANBAN_BOARD_ACTIVITY: KanbanModuleBasePath + '/kanban/boardActivity/board/{boardId}',
      CHANGE_BOARD_TITLE: KanbanModuleBasePath + '/kanban/renameBoardsTitle/board/{boardId}',
      ADD_BOARD_MEMBER: KanbanModuleBasePath + '/kanban/addBoardMember',
      GET_USERS: KanbanModuleBasePath + '/kanban/user',
    },
    card: {
      CREATE_CARD: KanbanModuleBasePath + '/kanban/createCard',
      GET_ALL_LIST_CARD: KanbanModuleBasePath + '/kanban/getAllListCard/{listId}',
      GET_CARD_BY_ID: KanbanModuleBasePath + '/kanban/getCard/card/{cardId}',
      EDIT_CARD: KanbanModuleBasePath + '/kanban/editCard/card/{cardId}',
      ARCHIVE_CARD: KanbanModuleBasePath + '/kanban/archiveCard',
      MOVE_CARD: KanbanModuleBasePath + '/kanban/moveCard',
      ADD_REMOVE_MEMBER: KanbanModuleBasePath + '/kanban/addRemoveMember',
      DELETE_CARD: KanbanModuleBasePath + '/kanban/deleteCard',
    },
    checkList: {
      CREATE_CHECKLIST: KanbanModuleBasePath + '/kanban/createChecklist',
      EDIT_CHECKLIST_ITEM_TEXT: KanbanModuleBasePath + '/kanban/editCheckListItemText',
      CHANGE_CHECKLIST_STATUS: KanbanModuleBasePath + '/kanban/changeCheckListStatus',
      DELETE_CHECKLIST:
        KanbanModuleBasePath + '/kanban/deleteCheckList/card/{cardId}/item/{itemId}',
    },
    list: {
      CREATE_LIST: KanbanModuleBasePath + '/kanban/createList',
      GET_BOARD_LIST: KanbanModuleBasePath + '/kanban/getBoardList/board/{boardId}',
      GET_LIST_BY_ID: KanbanModuleBasePath + '/kanban/getListById/list/{listId}',
      EDIT_LIST_TITLE: KanbanModuleBasePath + '/kanban/editListTitle',
      ARCHIVE_UNARCHIVE_LIST: KanbanModuleBasePath + '/kanban/archiveUnarchiveList',
      MOVE_LIST: KanbanModuleBasePath + '/kanban/moveList',
    },
  },
  taxSettings: {
    moduleVariable: 'CLIENT_PORTAL',
    moduleBasePath: ClientModuleBasePath,
    // POST_CREATE_TAX_SETTING: ClientModuleBasePath + "/taxSettings",
    // PUT_UPDATE_TAX_SETTING: ClientModuleBasePath + "/taxSettings/{taxSettingId}",
    GET_ALL_TAX_SETTINGS: ClientModuleBasePath + '/taxSettings',
    POST_SEARCH_TAX_SETTINGS: ClientModuleBasePath + '/taxSettings/search',
    POST_FIND_TAX_SETTING: ClientModuleBasePath + '/taxSettings/findOne',
  },
  documentTemplates: {
    moduleVariable: 'CLIENT_PORTAL',
    moduleBasePath: ClientModuleBasePath,
    POST_SAVE_DOCUMENT_CATEGORY: ClientModuleBasePath + '/documentCategory',
    PATCH_UPDATE_DOCUMENT_CATEGORY: ClientModuleBasePath + '/documentCategory/{documentCategoryId}',
    GET_DOCUMENT_CATEGORIES: ClientModuleBasePath + '/documentCategory/status/{status}',
    POST_SAVE_DOCUMENT_TEMPLATE: ClientModuleBasePath + '/documentTemplate',
    PATCH_UPDATE_DOCUMENT_TEMPLATE: ClientModuleBasePath + '/documentTemplate/{documentTemplateId}',
    DELETE_DOCUMENT_TEMPLATE: ClientModuleBasePath + '/documentTemplate/{documentTemplateId}',
    GET_DOCUMENT_TEMPLATES: ClientModuleBasePath + '/documentTemplate',
  },
  fileBrowser: {
    moduleVariable: 'FILE_BROWSER',
    moduleBasePath: FileBrowserBasePath,
    FILE_MANAGER_OPERATIONS_URL: FileBrowserBasePath + '/AmazonS3FileOperations',
    FILE_MANAGER_GET_IMAGE_URL: FileBrowserBasePath + '/AmazonS3GetImage',
    FILE_MANAGER_UPLOAD_CONTENT_URL: FileBrowserBasePath + '/AmazonS3Upload',
    FILE_MANAGER_DOWNLOAD_CONTENT_URL: FileBrowserBasePath + '/AmazonS3Download',
  },
  trust: {
    moduleVariable: 'TRUST',
    moduleBasePath: TrustModuleBasePath,
    trustProfile: {
      // GET_TRUST_CLIENT_DASHBOARD_STATS_BY_FINANCIAL_POSITION: TrustModuleBasePath + '',
      POST_SAVE_TRUST_CLIENT: TrustModuleBasePath + '/trust/createTrust',
      DELETE_TRUST_CLIENT:
        TrustModuleBasePath +
        '/trust/deleteTrust/trust/{trustId}/financialPosition/{trustFinancialPositionId}',

      GET_FIND_TRUST_CLIENT_BY_ID: TrustModuleBasePath + '/trust/getByIdTrust/trust/{trustId}',
      PATCH_UPDATE_TRUST_CLIENT_BY_ID:
        TrustModuleBasePath + '/trust/updateTrustById/trust/{trustId}',

      POST_CREATE_TRUST_BENEFICIARY:
        TrustModuleBasePath + '/trust/createBeneficiary/trust/{trustId}',
      PATCH_UPDATE_TRUST_BENEFICIARY_BY_ID:
        TrustModuleBasePath +
        '/trust/updateBeneficiary/trust/{trustId}/beneficiary/{beneficiaryId}',
      DELETE_TRUST_BENEFICIARY:
        TrustModuleBasePath +
        '/trust/deleteBeneficiary/trust/{trustId}/beneficiary/{beneficiaryId}',

      POST_CREATE_TRUST_INDIVIDUAL: TrustModuleBasePath + '/trust/createIndividual/trust/{trustId}',
      PATCH_UPDATE_TRUST_INDIVIDUAL_BY_ID:
        TrustModuleBasePath + '/trust/updateIndividual/trust/{trustId}/individual/{individualId}',
      DELETE_TRUST_INDIVIDUAL:
        TrustModuleBasePath + '/trust/deleteIndividual/trust/{trustId}/individual/{individualId}',

      POST_CREATE_CORPORATE_TRUST_DIRECTOR:
        TrustModuleBasePath + '/trust/createDirector/trust/{trustId}',
      PATCH_UPDATE_CORPORATE_TRUST_DIRECTOR:
        TrustModuleBasePath + '/trust/updateDirector/trust/{trustId}/director/{directorId}',
      DELETE_CORPORATE_TRUST_DIRECTOR:
        TrustModuleBasePath + '/trust/deleteDirector/trust/{trustId}/director/{directorId}',

      POST_SAVE_MULTIPLE_TRUST_CLIENT: TrustModuleBasePath + '/trust/createTrustsCSV',

      POST_CREATE_CORPORATE_TRUST_SHAREHOLDER:
        TrustModuleBasePath + '/trust/createShareholder/trust/{trustId}',
      PATCH_UPDATE_CORPORATE_TRUST_SHAREHOLDER:
        TrustModuleBasePath +
        '/trust/updateShareholder/trust/{trustId}/shareholder/{shareholderId}',
      DELETE_CORPORATE_TRUST_SHAREHOLDER:
        TrustModuleBasePath +
        '/trust/deleteShareholder/trust/{trustId}/shareholder/{shareholderId}',
    },
    trustClientList: {
      GET_ALL_TRUST_CLIENTS: '/trust/getAllTrust',
      FILTER_TRUST: TrustModuleBasePath + '/trust/filter',
      ADD_LINKED_ENTITIES: TrustModuleBasePath + '/trust/add-linked-entities',
      DELETE_LINKED_ENTITIES: TrustModuleBasePath + '/trust/delete-linked-entities',
    },
  },
  company: {
    moduleVariable: 'COMPANY',
    moduleBasePath: CompanyModuleBasePath,
    companyProfile: {
      POST_CREATE_COMPANY_CLIENT: CompanyModuleBasePath + '/company/createCompany',
      PATCH_UPDATE_COMPANY_BY_ID:
        CompanyModuleBasePath + '/company/updateCompanyById/company/{companyId}',
      GET_COMPANY_BY_ID: CompanyModuleBasePath + '/company/getByIdCompany/company/{companyId}',

      POST_CREATE_COMPANY_SHAREHOLDER:
        CompanyModuleBasePath + '/company/createShareholder/company/{companyId}',
      PATCH_UPDATE_COMPANY_SHAREHOLDER_BY_ID:
        CompanyModuleBasePath +
        '/company/updateShareholder/company/{companyId}/shareholder/{shareholderId}',
      DELETE_COMPANY_SHAREHOLDER_BY_ID:
        CompanyModuleBasePath +
        '/company/deleteShareholder/company/{companyId}/shareholder/{shareholderId}',

      POST_CREATE_COMPANY_DIRECTOR:
        CompanyModuleBasePath + '/company/createDirector/company/{companyId}',
      PATCH_UPDATE_COMPANY_DIRECTOR_BY_ID:
        CompanyModuleBasePath + '/company/updateDirector/company/{companyId}/director/{directorId}',
      DELETE_COMPANY_DIRECTOR_BY_ID:
        CompanyModuleBasePath + '/company/deleteDirector/company/{companyId}/director/{directorId}',

      POST_CREATE_MULTIPLE_COMPANY_CLIENT: CompanyModuleBasePath + '/company/createCompaniesCSV',

      // GET_COMPANY_BY_NAME:
      //   'https://superfundlookup.gov.au/xmlsearch/SflXmlSearch.asmx/SearchByName?name=a&guid=09ddefcf-eb98-4639-8fcb-28d6a2b650f7&activeFundsOnly=Y',
      // GET_COMPANY_BY_ABN: CompanyModuleBasePath + '/getCompanyByAbn/abn/{abn}',
    },
    companyClientList: {
      GET_ALL_COMPANY_CLIENTS: CompanyModuleBasePath + '/company/getAllCompany',
      FILTER_COMPANY: CompanyModuleBasePath + '/company/filter',
      ADD_LINKED_ENTITIES: CompanyModuleBasePath + '/company/add-linked-entities',
      DELETE_LINKED_ENTITIES: CompanyModuleBasePath + '/company/delete-linked-entities',
    },
  },
  partnership: {
    moduleVariable: 'PARTNERSHIP',
    moduleBasePath: PartnershipModuleBasePath,
    partnershipProfile: {
      // GET_PARTNERSHIP_CLIENT_DASHBOARD_STATS_BY_FINANCIAL_POSITION: PartnershipModuleBasePath + '',
      POST_SAVE_PARTNERSHIP_CLIENT: PartnershipModuleBasePath + '/partnership/createPartnership',
      GET_FIND_PARTNERSHIP_CLIENT_BY_ID:
        PartnershipModuleBasePath + '/partnership/getByIdPartnership/partnership/{partnershipId}',
      PATCH_UPDATE_PARTNERSHIP_CLIENT_BY_ID:
        PartnershipModuleBasePath +
        '/partnership/updatePartnershipById/partnership/{partnershipId}',

      POST_SAVE_MULTIPLE_PARTNERSHIP_CLIENT:
        PartnershipModuleBasePath + '/partnership/createPartnershipCSV',

      ADD_PARTNERSHIP_DATA:
        PartnershipModuleBasePath + '/partnership/addPartnership/partnership/{partnershipId}',
      PATCH_UPDATE_PARTNERSHIP_DATA_BY_ID:
        PartnershipModuleBasePath +
        '/partnership/updatePartnershipData/partnership/{partnershipId}/partnershipData/{partnershipDataId}',

      DELETE_PARTNERSHIP_DATA_BY_ID:
        PartnershipModuleBasePath +
        '/partnership/deletePartnershipData/partnership/{partnershipId}/partnershipData/{partnershipDataId}',
    },
    partnershipClientList: {
      GET_ALL_PARTNERSHIP_CLIENTS: '/partnership/getAllPartnership',
      FILTER_PARTNERSHIP: PartnershipModuleBasePath + '/partnership/filter',
      ADD_LINKED_ENTITIES: PartnershipModuleBasePath + '/partnership/add-linked-entities',
      DELETE_LINKED_ENTITIES: PartnershipModuleBasePath + '/partnership/delete-linked-entities',
    },
  },
  superFundCompany: {
    list: {
      GET_FIND_SUPERFUND_COMPANY_BY_ABN:
        'https://superfundlookup.gov.au/xmlsearch/SflXmlSearch.asmx/SearchByABN?abn={abn}&guid=fd2a01f9-07f2-413a-8fd1-3f3d20cdd351',
      GET_FIND_SUPERFUND_COMPANY_BY_NAME:
        'https://superfundlookup.gov.au/xmlsearch/SflXmlSearch.asmx/SearchByName?name={companyName}&guid=fd2a01f9-07f2-413a-8fd1-3f3d20cdd351&activeFundsOnly=Y',
    },
  },
  questionnaireTemplate: {
    template: {
      POST_SAVE_QUESTIONNAIRE_TEMPLATE: basePath + '/questionnaire-template/template',
      PATCH_UPDATE_QUESTIONNAIRE_TEMPLATE: basePath + '/questionnaire-template/template',
      POST_FILTER_QUESTIONNAIRE_TEMPLATES: basePath + '/questionnaire-template/template/filter',
      DELETE_QUESTIONNAIRE_TEMPLATES: basePath + '/questionnaire-template/template/{templateId}',
      GET_FILTER_ASSIGNED_MEMBERS:
        basePath + '/questionnaire-template/{templateId}/assigned-members',
      POST_ASSIGN_TEMPLATE_CLIENT_LEAD:
        basePath + '/questionnaire-template/template/assign-client-lead',
    },
    tag: {
      POST_SAVE_QUESTIONNAIRE_TEMPLATE_TAGS: basePath + '/questionnaire-template/template-tag',
      PATCH_UPDATE_QUESTIONNAIRE_TEMPLATE_TAGS: basePath + '/questionnaire-template/template-tag',
      POST_FILTER_QUESTIONNAIRE_TEMPLATES_TAGS:
        basePath + '/questionnaire-template/template-tag/filter',
      DELETE_QUESTIONNAIRE_TEMPLATES_TAGS: basePath + '/questionnaire-template/template-tag',
    },
  },
  assigneeMembers: {
    clientsAndLeads: {
      GET_ALL_ASSIGNABLE_CLIENTS_LEADS: basePath + '/assignee-members/client-lead',
    },
  },
};

export { API_URL };
