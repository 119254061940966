import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, Fade, Grid, Stack, IconButton, SvgIcon, Typography } from '@mui/material';

import { gridSpacing } from 'store/constant';
import { useCommonStyles } from 'styles/styles';

import MainCard from 'ui-component/cards/MainCard';
import PrimaryButton from 'ui-component/buttons/PrimaryButton';
import BasicDetailsCard from './components/BasicDetailsCard';
import CompanyDirectorList from './components/CompanyDirectorList';
import CompanyShareholderList from './components/CompanyShareholderList';
import ConfirmationModal from 'common/ConfirmationModal';
import ManageCompanyDirectorModal from 'modal/client/company/ManageCompanyDirectorModal';
import ManageCompanyShareholderModal from 'modal/client/company/ManageCompanyShareholderModal';

import AddEntitiesModal from './modal/AddEntitiesModal';

import * as deleteAnimationData from 'assets/animation/delete.json';
// import { ReactComponent as BackIcon } from 'assets/images/icons/back-outline.svg';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ReactComponent as LinkIcon } from 'assets/images/icons/network-computing-svgrepo-com 1.svg';

import {
  requestCompanyClientDetail,
  requestDeleteCompanyDirector,
  requestDeleteCompanyShareholder,
} from 'redux/Actions/companyClientsAction';

import { handleModals } from 'redux/Actions/handleModalAction';

const CompanyDashboard = () => {
  /**
   * Uses common classes
   */
  const {
    classes: { mainContainerWrapper },
  } = useCommonStyles();
  /**
   * Uses react-router-dom location, navigation
   */
  const location = useLocation();
  const navigate = useNavigate();
  /**
   * Redux integrations
   */
  const dispatch = useDispatch();
  //_company store
  const companyClientReducer = useSelector((state) => state?.companyClientReducer);
  const isCompanyClientDetailRequestLoading =
    companyClientReducer?.isCompanyClientDetailRequestLoading;
  const selectedCompanyClientDetail = companyClientReducer?.selectedCompanyClientDetail;
  const isDeleteCompanyDirectorLoading = companyClientReducer?.isDeleteCompanyDirectorLoading;
  const isDeleteCompanyShareholderLoading = companyClientReducer?.isDeleteCompanyShareholderLoading;

  //_states
  const [manageDirectorsModalData, setManageDirectorsModalData] = useState({
    isOpened: false,
    editData: null,
  });
  //_states
  const [manageShareholdersModalData, setManageShareholdersModalData] = useState({
    isOpened: false,
    editData: null,
  });

  const [confirmationModalData, setConfirmationModalData] = useState({
    isConfirmationOpen: false,
    confirmationModalHeader: '',
    confirmationModalContent: '',
    confirmationImage: deleteAnimationData,
    confirmationData: null,
    actionType: 'DELETE_DIRECTOR', //DELETE_DIRECTOR, DELETE_SHAREHOLDER
  });

  /**
   * Handle open Confirmation
   * @param {*} confirmationData
   */
  const handleOpenConfirmationModal = (confirmationData) => {
    if (typeof confirmationData === 'object' && confirmationData) {
      setConfirmationModalData((prev) => ({
        ...prev,
        ...confirmationData,
      }));
    }
  };

  /**
   * Close confirmation
   */
  const closeConfirmationAction = () => {
    setConfirmationModalData((prev) => ({
      ...prev,
      isConfirmationOpen: false,
      confirmationData: null,
    }));
  };
  /**
   * Confirmation handler
   */
  const modalConfirmAction = () => {
    if (confirmationModalData?.actionType === 'DELETE_DIRECTOR') {
      const requestDetails = {
        companyId: location?.state?.companyData?._id || selectedCompanyClientDetail?._id,
        directorId: confirmationModalData?.confirmationData?._id,
      };
      const handleSuccessCallback = (response) => {
        closeConfirmationAction();
        handleRefetchCompanyData && handleRefetchCompanyData();
      };
      dispatch(requestDeleteCompanyDirector(requestDetails, handleSuccessCallback));
    } else if (confirmationModalData?.actionType === 'DELETE_SHAREHOLDER') {
      const requestDetails = {
        companyId: location?.state?.companyData?._id || selectedCompanyClientDetail?._id,
        shareholderId: confirmationModalData?.confirmationData?._id,
      };
      const handleSuccessCallback = (response) => {
        closeConfirmationAction();
        handleRefetchCompanyData && handleRefetchCompanyData();
      };
      dispatch(requestDeleteCompanyShareholder(requestDetails, handleSuccessCallback));
    }
  };

  /**
   * Handle close directors management modal
   */
  const handleCloseDirectorsManageModal = () => {
    setManageDirectorsModalData({
      isOpened: false,
      editData: null,
    });
  };
  /**
   * Handle close shareholder management modal
   */
  const handleCloseShareholdersManageModal = () => {
    setManageShareholdersModalData({
      isOpened: false,
      editData: null,
    });
  };
  /**
   * handle refetch company data
   */
  const handleRefetchCompanyData = () => {
    const companyData = location?.state?.companyData;
    if (companyData?._id) {
      const requestDetails = {
        companyId: companyData?._id,
      };
      dispatch(requestCompanyClientDetail(requestDetails));
    }
  };

  /**
   * Handle navigate back
   * @param {*} event
   */
  const handleGoBack = (event) => {
    navigate(-1);
  };
  /**
   * Handle add new director
   * @param {*} event
   */
  const handleAddNewDirector = (event) => {
    setManageDirectorsModalData({
      isOpened: true,
      editData: null,
    });
  };
  /**
   * Handle add new shareholder
   * @param {*} event
   */
  const handleAddNewShareholder = (event) => {
    setManageShareholdersModalData({
      isOpened: true,
      editData: null,
    });
  };
  /**
   * Effect on mount
   */
  useEffect(() => {
    handleRefetchCompanyData();
  }, []);

  //modal reducer

  const modalReducer = useSelector((state) => state.modalReducer);

  const closeAddEntitiesModal = () => {
    dispatch(handleModals(false, 'COMPANY_ADD_ENTITIES_MODAL'));
  };

  const openAddEntitiesModal = () => {
    dispatch(handleModals(true, 'COMPANY_ADD_ENTITIES_MODAL'));
  };

  return (
    <Grid container spacing={gridSpacing} className={mainContainerWrapper}>
      <AddEntitiesModal
        openDialog={modalReducer.COMPANY_ADD_ENTITIES_MODAL.modalStatus}
        handleCloseDialog={closeAddEntitiesModal}
        linkedUser={
          selectedCompanyClientDetail?.linkedUsers ? selectedCompanyClientDetail?.linkedUsers : []
        }
      />

      <ManageCompanyDirectorModal
        isOpened={manageDirectorsModalData?.isOpened}
        handleClose={handleCloseDirectorsManageModal}
        companyDirectorEditData={manageDirectorsModalData?.editData}
        companyData={location?.state?.companyData}
        handleRefetchCompanyData={handleRefetchCompanyData}
      />
      <ManageCompanyShareholderModal
        isOpened={manageShareholdersModalData?.isOpened}
        handleClose={handleCloseShareholdersManageModal}
        companyShareholderEditData={manageShareholdersModalData?.editData}
        companyData={location?.state?.companyData}
        handleRefetchCompanyData={handleRefetchCompanyData}
      />
      <ConfirmationModal
        isConfirmationModal={confirmationModalData?.isConfirmationOpen}
        closeConfirmationAction={closeConfirmationAction}
        modalConfirmAction={modalConfirmAction}
        confirmationModalHeader={confirmationModalData?.confirmationModalHeader}
        confirmationModalContent={confirmationModalData?.confirmationModalContent}
        img={confirmationModalData?.confirmationImage}
        loading={isDeleteCompanyDirectorLoading || isDeleteCompanyShareholderLoading}
      />
      <Grid item xs={12}>
        <MainCard>
          <Grid container spacing={gridSpacing} justifyContent="space-between">
            <Grid item xs={12} sm={6}>
              <Typography
                sx={(theme) => ({
                  color: theme.palette.grey?.[800],
                  fontWeight: theme.typography.fontWeightBold,
                  fontSize: '1.25rem',
                })}
              >
                Company Dashboard
              </Typography>
              <Button
                startIcon={<ArrowBackIosIcon />}
                onClick={handleGoBack}
                sx={(theme) => ({
                  fontSize: '0.875rem',
                  color: theme.palette.blue?.[700],
                  fontWeight: theme.typography.fontWeightBold,
                })}
              >
                Back
              </Button>
            </Grid>
            <Grid container item justifyContent={'flex-end'} xs={12} sm={6}>
              <IconButton id={'client-invitation'} onClick={openAddEntitiesModal}>
                <SvgIcon viewBox="0 0 40 40" component={LinkIcon} />
              </IconButton>
            </Grid>
          </Grid>
        </MainCard>
      </Grid>
      <Grid item xs={12}>
        <BasicDetailsCard />
      </Grid>
      <Grid item xs={12} md={6}>
        <MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid item xs="auto">
              <Typography
                sx={(theme) => ({
                  color: theme.palette.grey?.[800],
                  fontWeight: theme.typography.fontWeightBold,
                  fontSize: '1.25rem',
                })}
              >
                Directors
              </Typography>
            </Grid>
            <Grid item xs>
              <Fade
                in={
                  !selectedCompanyClientDetail?.corporateTrustee?.name &&
                  !isCompanyClientDetailRequestLoading &&
                  selectedCompanyClientDetail?._id
                }
              >
                <Stack direction="row" justifyContent="flex-end">
                  <PrimaryButton label={`Add Director`} onClick={handleAddNewDirector} />
                </Stack>
              </Fade>
            </Grid>
            <Grid item xs={12}>
              <CompanyDirectorList
                companyData={location?.state?.companyData}
                setManageDirectorsModalData={setManageDirectorsModalData}
                handleOpenConfirmationModal={handleOpenConfirmationModal}
              />
            </Grid>
          </Grid>
        </MainCard>
      </Grid>
      <Grid item xs={12} md={6}>
        <MainCard>
          <Grid container spacing={gridSpacing}>
            <Grid item xs="auto">
              <Typography
                sx={(theme) => ({
                  color: theme.palette.grey?.[800],
                  fontWeight: theme.typography.fontWeightBold,
                  fontSize: '1.25rem',
                })}
              >
                Shareholders
              </Typography>
            </Grid>
            <Grid item xs>
              <Fade
                in={
                  !selectedCompanyClientDetail?.corporateTrustee?.name &&
                  !isCompanyClientDetailRequestLoading &&
                  selectedCompanyClientDetail?._id
                }
              >
                <Stack direction="row" justifyContent="flex-end">
                  <PrimaryButton label={`Add Shareholder`} onClick={handleAddNewShareholder} />
                </Stack>
              </Fade>
            </Grid>
            <Grid item xs={12}>
              <CompanyShareholderList
                companyData={location?.state?.companyData}
                setManageShareholdersModalData={setManageShareholdersModalData}
                handleOpenConfirmationModal={handleOpenConfirmationModal}
              />
            </Grid>
          </Grid>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default CompanyDashboard;
