import { useSelector } from 'react-redux';

import { Grid, SvgIcon } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';

import { gridSpacing } from 'store/constant';

import { deleteSvg, editSvg } from 'assets/svg/svgImg';
const CompanyDirectorList = (props) => {
  const {
    // setManageDirectorsModalData,
    handleOpenConfirmationModal,
  } = props;

  //_trust store
  const companyClientReducer = useSelector((state) => state?.companyClientReducer);
  const isCompanyClientDetailRequestLoading =
    companyClientReducer?.isCompanyClientDetailRequestLoading;
  const selectedCompanyClientDetail = companyClientReducer?.selectedCompanyClientDetail;

  /**
   * Get data grid column definition
   */
  const getDataGridColumns = () => {
    const directorsColumns = [
      {
        field: 'firstName',
        headerName: 'First name',
        flex: 1,
      },
      {
        field: 'primaryEmailAddress',
        headerName: 'Email ',
        flex: 1,
      },
      {
        field: 'primaryMobileNumber',
        headerName: 'Mobile Number',
        flex: 1,
      },
      {
        field: 'action',
        headerName: 'Action',
        flex: 1,
        type: 'actions',
        getActions: (params) => [
          <GridActionsCellItem
            key={'delete-key'}
            id="delete"
            icon={<SvgIcon viewBox="0 0 25 25">{deleteSvg}</SvgIcon>}
            onClick={(event) => handleActionClick(event, 'DELETE', params)}
            label="Delete"
          />,
        ],
      },
    ];

    return directorsColumns;
  };
  /**
   * Handle datagrid action click
   * @param {*} event
   * @param {*} actionType
   * @param {*} gridParams
   */
  const handleActionClick = (event, actionType, gridParams) => {
    if (actionType === 'DELETE') {
      const deletingDirectorData = gridParams?.row;
      handleOpenConfirmationModal({
        isConfirmationOpen: true,
        confirmationModalHeader: 'Delete Director!',
        confirmationModalContent: 'Do you want to remove this director?',
        confirmationData: deletingDirectorData,
        actionType: 'DELETE_DIRECTOR',
      });
    }
  };
  /**
   * Data grid unique key
   * @param {*} row
   * @returns {string} key
   */
  const getRowId = (row) => row?._id;

  const handleMembers = (data) => {
    let rows = [];
    Array.isArray(data) &&
      data.forEach((item, index) => {
        if (item?.clientId?._id)
          if (item?.clientType === 'Partner') {
            rows.push({
              _id: item._id, // Unique identifier for each row
              clientId: item.clientId._id,
              firstName: item.clientId?.partner?.firstName,
              surName: item.clientId?.partner?.surName,
              primaryEmailAddress: item.clientId?.partner?.primaryEmailAddress,
              dateOfBirth: item.clientId?.partner?.dateOfBirth,
              gender: item.clientId?.partner?.gender,
              primaryMobileNumber: item.clientId?.partner?.primaryMobileNumber,
              clientType: item.clientType,
            });
          } else {
            rows.push({
              _id: item._id, // Unique identifier for each row
              clientId: item.clientId._id,
              firstName: item.clientId.firstName,
              surName: item.clientId.surName,
              primaryEmailAddress: item.clientId.primaryEmailAddress,
              dateOfBirth: item.clientId.dateOfBirth,
              gender: item.clientId.gender,
              primaryMobileNumber: item.clientId.primaryMobileNumber,
              clientType: item.clientType || 'Client',
            });
          }
      });

    return rows;
  };

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <DataGrid
          rows={handleMembers(selectedCompanyClientDetail?.directors)}
          columns={getDataGridColumns()}
          getRowId={getRowId}
          rowsPerPageOptions={[5, 10]}
          // checkboxSelection
          sx={{ height: '60vh' }}
          loading={isCompanyClientDetailRequestLoading}
        />
      </Grid>
    </Grid>
  );
};

export default CompanyDirectorList;
