import React, { memo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
  IconButton,
  Grid,
  Box,
  Autocomplete,
  TextField,
  SvgIcon,
  CircularProgress,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as DeleteIcon } from 'assets/images/trash.svg';

import {
  addLinkedEntitiesCompany,
  deleteLinkedEntitiesCompany,
} from 'redux/Actions/companyClientsAction';
import { requestFilterPrimaryClients } from 'redux/Actions/clientsAction';

import { useCommonStyles } from 'styles/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddEntitiesModal = (props) => {
  const { openDialog, handleCloseDialog, linkedUser } = props;

  /**
   * uses react-router-dom location
   */
  const location = useLocation();

  const navigate = useNavigate();

  /**
   * Uses common classes
   */
  const { classes: commonClasses } = useCommonStyles();

  /**
   * Redux integrations
   */

  const dispatch = useDispatch();
  const clientReducer = useSelector((state) => state.clientReducer);

  const filterPrimaryClientsSuccessData = clientReducer?.filterPrimaryClientsSuccessData;
  const filterPrimaryClientsLoading = clientReducer?.filterPrimaryClientsLoading;

  const companyClientReducer = useSelector((state) => state.companyClientReducer);

  const addLinkedEntitiesCompanyLoading = companyClientReducer?.addLinkedEntitiesCompanyLoading;
  const deleteLinkedEntitiesCompanyLoading =
    companyClientReducer?.deleteLinkedEntitiesCompanyLoading;

  const [clientInputValue, setClientInputValue] = useState('');

  /**
   * Request for client list data
   */

  const handleGetClientList = (newInputValue) => {
    const handleSuccessCallback = (response) => {};

    const handleFailedCallback = (error) => {
      console.log(error);
    };

    newInputValue &&
      newInputValue.length > 2 &&
      dispatch(
        requestFilterPrimaryClients(
          { name: newInputValue },
          handleSuccessCallback,
          handleFailedCallback
        )
      );
  };

  //open client profile
  const openClientProfile = (clientData) => {
    const payload = {
      clientId: clientData._id,
      clientType: clientData.type,
      companyId: location?.state?.companyData?._id,
    };
    const handleSuccessCallback = (response) => {};

    const handleFailedCallback = (error) => {
      console.log(error);
    };

    dispatch(addLinkedEntitiesCompany(payload, handleSuccessCallback, handleFailedCallback));
  };

  /**
   * Handle on action click
   * @param {MouseEvent} event
   */
  const handleActionClick = (event, actionType, dataGridRowParams) => {
    if (actionType === 'DELETE') {
      const payload = {
        clientId: dataGridRowParams?.id,
        companyId: location?.state?.companyData?._id,
      };
      const handleSuccessCallback = (response) => {};

      const handleFailedCallback = (error) => {
        console.log(error);
      };
      dispatch(deleteLinkedEntitiesCompany(payload, handleSuccessCallback, handleFailedCallback));
    }
  };

  const columns = [
    // { field: '_id', headerName: '_id', flex: 1 },
    {
      field: 'firstName',
      headerName: 'First name',
      width: 150,
      flex: 1,
      renderCell: (params) => {
        if (params?.row?.clientType === 'Partner') {
          return params?.row?.clientId?.partner?.firstName;
        } else {
          return params?.row?.clientId?.firstName;
        }
      },
    },
    {
      field: 'surName',
      headerName: 'Last name',
      width: 150,
      editable: true,
      flex: 1,
      renderCell: (params) => {
        if (params?.row?.clientType === 'Partner') {
          return params?.row?.clientId?.partner?.surName;
        } else {
          return params?.row?.clientId?.surName;
        }
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          key={'delete-id'}
          color="red"
          id="rejectIncomingPayment"
          icon={<SvgIcon viewBox="0 0 25 25" component={DeleteIcon} />}
          onClick={(event) => handleActionClick(event, 'DELETE', params)}
          label="Delete"
        />,
      ],
    },
  ];

  const handleRowClick = (params) => {
    if (params?.row?.clientId) {
      const clientData = params?.row?.clientId;
      navigate(`/main/clientDashboard`, { state: { clientData } });
      handleCloseDialog();
    }
  };

  return (
    <Dialog
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      // onClose={handleCloseDialog}
      aria-describedby="new-goal-dialog-"
      fullWidth
      maxWidth="md"
      scroll="body"
    >
      <DialogTitle className={commonClasses.modalTitleBox}>
        <Typography className={commonClasses.modalTitle}>Linked entities</Typography>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={commonClasses.modalContent}>
        <Grid container spacing={2}>
          <Grid container item sx={12}>
            <Box marginTop={2} width={'100%'}>
              {!addLinkedEntitiesCompanyLoading && (
                <Autocomplete
                  // disableClearable={true}
                  // disableCloseOnSelect={true}
                  value={null}
                  onChange={(event, newValue) => {
                    // setSelectedClient(newValue);
                    openClientProfile(newValue);
                  }}
                  inputValue={clientInputValue}
                  onInputChange={(event, newInputValue) => {
                    setClientInputValue(newInputValue);
                    // dispatch(requestFilterClientList({ name: newInputValue }));
                    handleGetClientList(newInputValue);
                  }}
                  loading={filterPrimaryClientsLoading}
                  id="auto-complete-search-by-name"
                  options={
                    filterPrimaryClientsSuccessData &&
                    Array.isArray(filterPrimaryClientsSuccessData)
                      ? filterPrimaryClientsSuccessData
                      : []
                  }
                  getOptionLabel={(option) => `${option.firstName} ${option.surName}`}
                  filterOptions={(options, state) => {
                    const inputValue = state.inputValue.toLowerCase();
                    return options.filter(
                      (option) =>
                        option.firstName.toLowerCase().includes(inputValue) ||
                        option.surName.toLowerCase().includes(inputValue)
                    );
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{ textTransform: 'capitalize' }}
                      onClick={() => openClientProfile(option)}
                    >
                      {`${option?.surName}, ${option?.firstName}`}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Client Name"
                      slotProps={{
                        input: {
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {true ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        },
                      }}
                    />
                  )}
                  sx={(theme) => ({
                    width: '100%',
                    minWidth: '40ch',
                    '& .MuiAutocomplete-input': {
                      padding: '0.325rem 0 !important',
                    },
                  })}
                />
              )}
            </Box>
          </Grid>
          <Grid container item sx={12}>
            <DataGrid
              autoHeight={true}
              rows={linkedUser}
              columns={columns}
              getRowId={(row) => row?._id || row._id}
              checkboxSelection={false}
              loading={addLinkedEntitiesCompanyLoading || deleteLinkedEntitiesCompanyLoading}
              initialState={{
                pagination: {
                  pageSize: 10,
                },
              }}
              sx={(theme) => ({
                backgroundColor: theme.palette.white,
              })}
              rowsPerPageOptions={[5, 10, 20]}
              pagination
              onRowClick={handleRowClick}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default memo(AddEntitiesModal);
