import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Autocomplete, TextField, CircularProgress } from '@mui/material';

import { requestFilterPrimaryClients } from 'redux/Actions/clientsAction';

const IndividualSearch = (props) => {
  const { handleCloseDialog, openDialog } = props;
  /**
   * uses react-router-dom location
   */

  const navigate = useNavigate();

  /**
   * Redux integrations
   */

  const dispatch = useDispatch();

  const clientReducer = useSelector((state) => state.clientReducer);

  const filterPrimaryClientsSuccessData = clientReducer?.filterPrimaryClientsSuccessData;
  const filterPrimaryClientsLoading = clientReducer?.filterPrimaryClientsLoading;

  const [clientInputValue, setClientInputValue] = useState('');

  /**
   * Request for client list data
   */

  const handleGetClientList = (newInputValue) => {
    const handleSuccessCallback = (response) => {};

    const handleFailedCallback = (error) => {
      console.log(error);
    };

    newInputValue &&
      newInputValue.length > 2 &&
      dispatch(
        requestFilterPrimaryClients(
          { name: newInputValue },
          handleSuccessCallback,
          handleFailedCallback
        )
      );
  };

  //open client profile
  const openClientProfile = (clientData) => {
    navigate(`/main/clientDashboard`, { state: { clientData } });
    handleCloseDialog();
  };

  const autocompleteRef = useRef(null);

  useEffect(() => {
    if (openDialog && autocompleteRef.current) {
      // Focus the input field of the Autocomplete when the modal opens
      const input = autocompleteRef.current.querySelector('input');
      if (input) {
        input.focus();
      }
    }
  }, [openDialog]);

  return (
    <Autocomplete
      ref={autocompleteRef}
      value={null}
      onChange={(event, newValue) => {
        openClientProfile(newValue);
      }}
      inputValue={clientInputValue}
      onInputChange={(event, newInputValue) => {
        setClientInputValue(newInputValue);

        handleGetClientList(newInputValue);
      }}
      loading={filterPrimaryClientsLoading}
      id="auto-complete-search-by-name"
      options={
        filterPrimaryClientsSuccessData && Array.isArray(filterPrimaryClientsSuccessData)
          ? filterPrimaryClientsSuccessData
          : []
      }
      getOptionLabel={(option) => `${option.firstName} ${option.surName}`}
      // filterOptions={(options, state) => {
      //   const inputValue = state.inputValue.toLowerCase();
      //   return options.filter(
      //     (option) =>
      //       option.firstName.toLowerCase().includes(inputValue) ||
      //       option.surName.toLowerCase().includes(inputValue)
      //   );
      // }}

      filterOptions={(options, state) => {
        const inputValue = state.inputValue.toLowerCase();
        return options.filter((option) => {
          const clientMatch =
            option.firstName.toLowerCase().includes(inputValue) ||
            option.surName.toLowerCase().includes(inputValue);

          const partnerMatch =
            option.partner?.firstName.toLowerCase().includes(inputValue) ||
            option.partner?.surName.toLowerCase().includes(inputValue);

          return clientMatch || partnerMatch;
        });
      }}
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          style={{ textTransform: 'capitalize' }}
          onClick={() => openClientProfile(option)}
        >
          {/* {option?.hasPartner
            ? `${option?.surName}, ${option?.firstName} and ${option?.partner?.firstName}`
            : `${option?.surName}, ${option?.firstName}`} */}
          {`${option?.surName}, ${option?.firstName}`}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Client/Partner Name"
          slotProps={{
            input: {
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {true ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            },
          }}
          autoFocus
        />
      )}
      sx={(theme) => ({
        width: '100%',
        minWidth: '40ch',
        '& .MuiAutocomplete-input': {
          padding: '0.325rem 0 !important',
        },
      })}
    />
  );
};

export default IndividualSearch;
