import { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import {
  Box,
  Button,
  Divider,
  Fade,
  Grid,
  Grow,
  LinearProgress,
  Stack,
  linearProgressClasses,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { useCommonStyles } from 'styles/styles';
import { gridSpacing } from 'store/constant';

import CommonModal from 'common/material/CommonModal';
import FormikTextField from 'common/formik/FormikTextField';
import FormikDatePicker from 'common/formik/FormikDatePicker';
import DropDownAutoCom from 'common/material/DropDownAutoCom';
import FormikCheckbox from 'common/formik/FormikCheckbox';
import FormikSwitch from 'common/formik/FormikSwitch';
import DropDown from 'common/material/Dropdown';
import CurrencyFieldATM from 'common/formik/CurrencyFieldATM';

import { getOptionsData } from 'utils/compositeFormat';
import {
  DynamicAgeGenerator,
  DynamicHoursWorkedGeneratorFormik,
  DynamicOccupationFieldGeneratorFormik,
} from 'views/client/updateClient/individual/components/formikComponents';
import { getAgeFromDOB } from 'utils/date-of-birth';
import AutoClearClientSearchFormik from './components/AutoClearClientSearchFormik';
import EditInitialDataFormik from './components/EditInitialDataFormik';

import { AddCircleOutlineRounded, Edit, SaveOutlined } from '@mui/icons-material';

import { requestClientDetail, requestFilterPrimaryClients } from 'redux/Actions/clientsAction';
import {
  requestCompanyClientList,
  requestSaveCompanyShareholder,
  requestUpdateCompanyShareholder,
} from 'redux/Actions/companyClientsAction';
import { requestSMSFClientList } from 'redux/Actions/smsfClientsAction';
import { requestTrustClientList } from 'redux/Actions/trustClientsAction';

const ModalContent = (props) => {
  const { handleCloseDialog, companyShareholderEditData, handleRefetchCompanyData, companyData } =
    props;
  /**
   * Uses common classes
   */
  const {
    classes: {
      clearBtn,
      saveBtn,
      updateBtn,
      formSubContainer,
      mainDivider,
      combinedFieldLeftSelect,
      combinedFieldRightInput,
    },
  } = useCommonStyles();

  /**
   * Redux integrations
   */
  const dispatch = useDispatch();

  ///composite store
  const compositeRequestLoading = useSelector(
    (state) => state.compositeDataReducer
  )?.compositeRequestLoading;
  //_company store
  const companyClientReducer = useSelector((state) => state?.companyClientReducer);
  const companyClientListData = companyClientReducer?.companyClientListData;
  const selectedCompanyClientDetail = companyClientReducer?.selectedCompanyClientDetail;
  const isCompanyClientDetailRequestLoading =
    companyClientReducer?.isCompanyClientDetailRequestLoading;
  //_client data reducer
  const clientReducer = useSelector((state) => state.clientReducer);
  const filterClientsRequestLoading = clientReducer?.filterClientsRequestLoading;

  const isClientDetailRequestLoading = clientReducer?.isClientDetailRequestLoading;
  //_smsf client store
  const smsfClientReducer = useSelector((state) => state?.smsfClientReducer);
  const isSMSFClientListRequestLoading = smsfClientReducer?.isSMSFClientListRequestLoading;
  const smsfClientListData = smsfClientReducer?.smsfClientListData;
  //_trust client store
  const trustClientReducer = useSelector((state) => state?.trustClientReducer);
  const trustClientListData = trustClientReducer?.trustClientListData;
  //edit mode
  const isEditMode = !!companyShareholderEditData;

  const shareholderExistingTypeOptions = [
    // {
    //   id: 'Director',
    //   value: 'Director',
    //   label: 'Director',
    //   name: 'Director',
    // },
    {
      id: 'Individual',
      value: 'Individual',
      label: 'Individual',
      name: 'Individual',
    },
    {
      id: 'SMSF',
      value: 'SMSF',
      label: 'SMSF',
      name: 'SMSF',
    },
    {
      id: 'Company',
      value: 'Company',
      label: 'Company',
      name: 'Company',
    },
    {
      id: 'Trust',
      value: 'Trust',
      label: 'Trust',
      name: 'Trust',
    },
  ];
  // const [shareholderExistingTypeOptions, setShareholderExistingTypeOptions] = useState();
  const [companyShareholderClientOptions, setCompanyShareholderClientOptions] = useState([]);
  const [companyShareholderSearchValue, setCompanyShareholderSearchValue] = useState('');
  const [selectedExistingClientData, setSelectedExistingClientData] = useState(null);

  const [isExistingClient, setIsExistingClient] = useState(true);
  /**
   * Memoized option data
   */
  const optionsData = useMemo(() => {
    let [
      titleOptions,
      gender,
      maritalStatus,
      occupationStatuses,
      occupations,
      employmentStatuses,
      employmentTypes,
    ] = [
      getOptionsData('title'),
      getOptionsData('gender'),
      getOptionsData('maritalStatus'),
      getOptionsData('occupationStatuses'),
      getOptionsData('occupations'),
      getOptionsData('employmentStatuses'),
      getOptionsData('employmentTypes'),
    ];
    return {
      titleOptions,
      gender,
      maritalStatus,
      occupationStatuses,
      occupations,
      employmentStatuses,
      employmentTypes,
    };
  }, [compositeRequestLoading]);

  //_Initial Values
  const initialValues = {
    isExistingClient: !!companyShareholderEditData?.isExistingClient,
    shareholderExistingType: companyShareholderEditData?.directorId
      ? 'Director'
      : companyShareholderEditData?.clientId
      ? 'Individual'
      : companyShareholderEditData?.companyId
      ? 'Company'
      : companyShareholderEditData?.trustId
      ? 'Trust'
      : companyShareholderEditData?.smsfId
      ? 'SMSF'
      : 'Individual',

    clientId: companyShareholderEditData?.clientId?._id || null,
    companyId: companyShareholderEditData?.companyId?._id || null,
    trustId: companyShareholderEditData?.trustId?._id || null,
    smsfId: companyShareholderEditData?.smsfId?._id || null,

    percentage: companyShareholderEditData?.percentage || '',

    //*Individual
    title: companyShareholderEditData?.title || '',
    firstName: companyShareholderEditData?.firstName || '',
    middleName: companyShareholderEditData?.middleName || '',
    surName: companyShareholderEditData?.surName || '',
    preferredName: companyShareholderEditData?.preferredName || '',
    gender: companyShareholderEditData?.gender || '',
    dateOfBirth:
      (companyShareholderEditData?.dateOfBirth &&
        new Date(companyShareholderEditData?.dateOfBirth)) ||
      null,
    age: getAgeFromDOB(
      companyShareholderEditData?.dateOfBirth && new Date(companyShareholderEditData?.dateOfBirth),
      true
    ),
    // lifeExpectancy: companyShareholderEditData?.lifeExpectancy || '',
    maritalStatus: companyShareholderEditData?.maritalStatus || '',
    hasPartner: !!companyShareholderEditData?.hasPartner,
    // idType: companyShareholderEditData?.idType || '',
    // idNumber: companyShareholderEditData?.idNumber || '',
    occupationStatus: companyShareholderEditData?.occupationStatus || '',
    occupationStartedDate: companyShareholderEditData?.occupationStartedDate || null,
    occupation:
      (
        companyShareholderEditData?.occupation &&
        optionsData?.occupations?.find &&
        optionsData.occupations.find(
          (singleOccupation) => singleOccupation?.name === companyShareholderEditData?.occupation
        )
      )?.value || '',
    employerName: companyShareholderEditData?.employerName || '',
    employmentStatus: companyShareholderEditData?.employmentStatus || '',
    employmentType: companyShareholderEditData?.employmentType || '',
    hoursWorked: companyShareholderEditData?.hoursWorked || '',
    yearsInCurrentOccupation: companyShareholderEditData?.yearsInCurrentOccupation || '',
    desiredRetirementAge: companyShareholderEditData?.desiredRetirementAge || '',

    primaryEmailAddress: companyShareholderEditData?.primaryEmailAddress || '',
    homePhoneNumber: companyShareholderEditData?.homePhoneNumber || '',
    primaryMobileNumber: companyShareholderEditData?.primaryMobileNumber || '',
    hasPrivateHealthCover: !!companyShareholderEditData?.hasPrivateHealthCover,
    smoker: !!companyShareholderEditData?.smoker,
    emailWeeklyTaskSummary: !!companyShareholderEditData?.emailWeeklyTaskSummary,
    taxFileNumber: companyShareholderEditData?.taxFileNumber || '',
    clientType: companyShareholderEditData?.clientType || 'Client',

    //*company, trust, smsf
    name: companyShareholderEditData?.name || '',
    abn: companyShareholderEditData?.abn || '',
    tfn: companyShareholderEditData?.tfn || '',
    established:
      (companyShareholderEditData?.established &&
        new Date(companyShareholderEditData?.established)) ||
      '',
    //options loading reinitialize
    isOptionLoading: compositeRequestLoading ? '' : '  ',
  };

  /**
  |--------------------------------------------------
  | validate existing client as company shareholder
  |--------------------------------------------------
  */
  Yup.addMethod(Yup.number, 'validateTotalPercentage', function (message) {
    return this.test('validateTotalPercentage', message, function (value) {
      const { path, createError, parent } = this;

      const existingCompanyShareholders = selectedCompanyClientDetail?.shareholder || [];
      const sumOfCurrentShareholderPercentage = existingCompanyShareholders.reduce(
        (prevPercentage, currentShareholder, currentIndex, currentArray) => {
          let sum =
            prevPercentage +
            ((!isNaN(+currentShareholder?.percentage) && +currentShareholder?.percentage) || 0);
          if (isEditMode) {
            sum = sum - companyShareholderEditData?.percentage;
          }
          return sum;
        },
        0
      );
      const isSumExceedsHundred = sumOfCurrentShareholderPercentage + +value > 100;
      if (isSumExceedsHundred && value) {
        return createError({
          path: path,
          message: message,
        });
      } else {
        return true;
      }
    });
  });

  /**
  |--------------------------------------------------
  | validate existing client as company shareholder
  |--------------------------------------------------
  */
  Yup.addMethod(Yup.string, 'validateExistingShareholderTypeClient', function (message) {
    return this.test('validateExistingShareholderTypeClient', message, function (value) {
      const { path, createError, parent } = this;
      const shareholderExistingType = parent?.shareholderExistingType || 'Individual';
      const idKey =
        (shareholderExistingType === 'Director'
          ? 'directorId'
          : shareholderExistingType === 'Individual'
          ? 'clientId'
          : shareholderExistingType === 'SMSF'
          ? 'smsfId'
          : shareholderExistingType === 'Company'
          ? 'companyId'
          : shareholderExistingType === 'Trust' && 'trustId') || 'clientId';
      const existingCompanyShareholders = selectedCompanyClientDetail?.shareholder || [];
      const isClientIdAlreadyExistInDataList =
        Array.isArray(existingCompanyShareholders) &&
        existingCompanyShareholders.find(
          (companyShareholderDetail) => companyShareholderDetail?.[idKey]?._id === value
        );
      if (isClientIdAlreadyExistInDataList && value && !isEditMode) {
        return createError({
          path: path,
          message: message,
        });
      } else {
        return true;
      }
    });
  });
  //_Validation Schema
  const validationSchema = Yup.object().shape({
    isExistingClient: Yup.boolean().required('Required!').nullable(),
    shareholderExistingType: Yup.string().nullable(),

    directorId: Yup.string().when('isExistingClient', {
      is: (isExistingClient) => isExistingClient,
      then: Yup.string().when('shareholderExistingType', {
        is: (shareholderExistingType) => shareholderExistingType === 'Director',
        then: Yup.string()
          .required('Please Search and Select a director!')
          // .validateExistingShareholderTypeClient('Selected director is already added!')
          .nullable(),
        otherwise: Yup.string().nullable(),
      }),
      otherwise: Yup.string().nullable(),
    }),

    clientId: Yup.string().when('isExistingClient', {
      is: (isExistingClient) => isExistingClient,
      then: Yup.string().when('shareholderExistingType', {
        is: (shareholderExistingType) => shareholderExistingType === 'Individual',
        then: Yup.string()
          .required('Please Search and Select a client!')
          // .validateExistingShareholderTypeClient('Selected client is already added!')
          .nullable(),
        otherwise: Yup.string().nullable(),
      }),
      otherwise: Yup.string().nullable(),
    }),

    smsfId: Yup.string().when('isExistingClient', {
      is: (isExistingClient) => isExistingClient,
      then: Yup.string().when('shareholderExistingType', {
        is: (shareholderExistingType) => shareholderExistingType === 'SMSF',
        then: Yup.string()
          .required('Please Search and Select a SMSF!')
          .validateExistingShareholderTypeClient('Selected SMSF is already added!')
          .nullable(),
        otherwise: Yup.string().nullable(),
      }),
      otherwise: Yup.string().nullable(),
    }),

    companyId: Yup.string().when('isExistingClient', {
      is: (isExistingClient) => isExistingClient,
      then: Yup.string().when('shareholderExistingType', {
        is: (shareholderExistingType) => shareholderExistingType === 'Company',
        then: Yup.string()
          .required('Please Search and Select a Company!')
          .validateExistingShareholderTypeClient('Selected Company is already added!')
          .nullable(),
        otherwise: Yup.string().nullable(),
      }),
      otherwise: Yup.string().nullable(),
    }),

    trustId: Yup.string().when('isExistingClient', {
      is: (isExistingClient) => isExistingClient,
      then: Yup.string().when('shareholderExistingType', {
        is: (shareholderExistingType) => shareholderExistingType === 'Trust',
        then: Yup.string()
          .required('Please Search and Select a Trust!')
          .validateExistingShareholderTypeClient('Selected Trust is already added!')
          .nullable(),
        otherwise: Yup.string().nullable(),
      }),
      otherwise: Yup.string().nullable(),
    }),
    percentage: Yup.number()
      .min(0, 'Invalid Percentage!')
      .max(100, 'Invalid Percentage!')
      .required('Required!')
      .typeError('Invalid Percentage!')
      .validateTotalPercentage('Shareholder Percentage exceeds 100%!')
      .nullable(),

    // title: Yup.string().when('shareholderExistingType', {
    //   is: (shareholderExistingType) => shareholderExistingType === 'Individual',
    //   then: Yup.string().required('Required !').typeError('Invalid Title!').nullable(),
    //   otherwise: Yup.string().nullable(),
    // }),
    firstName: Yup.string().when('shareholderExistingType', {
      is: (shareholderExistingType) => shareholderExistingType === 'Individual',
      then: Yup.string().nullable().required('Required !').typeError('Invalid First Name!'),
      otherwise: Yup.string().nullable(),
    }),
    surName: Yup.string().when('shareholderExistingType', {
      is: (shareholderExistingType) => shareholderExistingType === 'Individual',
      then: Yup.string().nullable().required('Required !').typeError('Invalid Surname!'),
      otherwise: Yup.string().nullable(),
    }),
    // preferredName: Yup.string().when('shareholderExistingType', {
    //   is: (shareholderExistingType) => shareholderExistingType === 'Individual',
    //   then: Yup.string().nullable().required('Required !').typeError('Invalid Preferred Name!'),
    //   otherwise: Yup.string().nullable(),
    // }),
    // gender: Yup.string().when('shareholderExistingType', {
    //   is: (shareholderExistingType) => shareholderExistingType === 'Individual',
    //   then: Yup.string().nullable().required('Required !').typeError('Invalid Gender!'),
    //   otherwise: Yup.string().nullable(),
    // }),
    // dateOfBirth: Yup.date().when('shareholderExistingType', {
    //   is: (shareholderExistingType) => shareholderExistingType === 'Individual',
    //   then: Yup.date().nullable().required('Required !').typeError('Invalid Date!'),
    //   otherwise: Yup.date().nullable().typeError('Invalid Date!'),
    // }),
    // primaryEmailAddress: Yup.string().when('shareholderExistingType', {
    //   is: (shareholderExistingType) => shareholderExistingType === 'Individual',
    //   then: Yup.string()
    //     .email('Invalid Email Address!')
    //     .nullable()
    //     .required('Required!')
    //     .typeError('Invalid Email Address!'),
    //   otherwise: Yup.string().nullable(),
    // }),
    // maritalStatus: Yup.string().when('shareholderExistingType', {
    //   is: (shareholderExistingType) => shareholderExistingType === 'Individual',
    //   then: Yup.string().nullable().required('Required !').typeError('Invalid Marital Status!'),
    //   otherwise: Yup.string().nullable(),
    // }),
    middleName: Yup.string().nullable().typeError('Invalid Middle Name!'),
    age: Yup.string().nullable().typeError('Invalid Age!'),

    primaryMobileNumber: Yup.string().nullable().typeError('Invalid Mobile Number!'),
    hasPrivateHealthCover: Yup.boolean().typeError('Invalid!').nullable(),
    smoker: Yup.boolean().typeError('Invalid!').nullable(),
    taxFileNumber: Yup.string().nullable().typeError('Invalid Tax File Number!'),

    //SMSF, Company, Trust field validations
    name: Yup.string().nullable(),
    abn: Yup.string()
      // .matches(/^[0-9]{11}$/, 'Only 11 digits allowed!')
      .nullable(),
    tfn: Yup.number().typeError('Invalid TFN!').nullable(),
    established: Yup.date().nullable().typeError('Invalid Date!'),
  });

  /**
   *
   * @param {*} event
   * @param {*} fieldValue
   * @param {*} reason
   * @returns
   */
  const handleClientSearchChange = (event, fieldValue, reason, shareholderType) => {
    setCompanyShareholderSearchValue(fieldValue);
    if ((!fieldValue || fieldValue?.length >= 3) && reason !== 'reset') {
      if (shareholderType === 'Director') {
        handleFilterExistingDirectorByName(fieldValue);
      } else if (shareholderType === 'Individual') {
        handleFilterExistingClientsByName(fieldValue);
      } else if (shareholderType === 'SMSF') {
        handleFilterExistingSMSFByName(fieldValue);
      } else if (shareholderType === 'Company') {
        handleFilterExistingCompanyByName(fieldValue);
      } else if (shareholderType === 'Trust') {
        handleFilterExistingTrustByName(fieldValue);
      }
    }
  };
  /**
   * Handle Save and add another company shareholder
   * @param {*} event
   * @param {*} formik
   */
  const handleSaveAndAddAnotherCompanyShareholder = (event, formik) => {
    formik
      .validateForm(formik.values)
      .then((errorObj) => {
        let tempErrorObj = { ...(errorObj || {}) };
        let tempTouched = { ...(formik.touched || {}) };
        Object.keys(tempErrorObj).forEach((singleKey) => {
          tempTouched[singleKey] = !!tempErrorObj[singleKey];
        });
        let isError = typeof tempErrorObj === 'object' && !!Object.keys(tempErrorObj)?.length;
        if (isError) {
          formik.setTouched(tempTouched, true);
        } else {
          onSubmit(formik?.values, formik, false);
        }
      })
      .catch((error) => {})
      .finally((value) => {});
  };
  /**
   * Handle formik submission
   * @param {*} values
   * @param {*} submitProps
   */
  const onSubmit = (values, submitProps, isAutoClose = true) => {
    submitProps?.setSubmitting?.(true);
    if (!isEditMode) {
      const companyShareholderSaveData = {
        requestBody: {
          isExistingClient: values?.isExistingClient,
          directorId: values?.directorId || undefined,
          clientId: values?.clientId || undefined,
          smsfId: values?.smsfId || undefined,
          companyId: values?.companyId || undefined,
          trustId: values?.trustId || undefined,
          title: values?.title || undefined,
          firstName: values?.firstName || undefined,
          middleName: values?.middleName || undefined,
          surName: values?.surName || undefined,
          preferredName: values?.preferredName || undefined,
          occupationStatus: values?.occupationStatus || undefined,
          dateOfBirth: values?.dateOfBirth || undefined,
          gender: values?.gender || undefined,
          maritalStatus: values?.maritalStatus || undefined,
          primaryMobileNumber: values?.primaryMobileNumber || undefined,
          primaryEmailAddress: values?.primaryEmailAddress || undefined,
          occupationStartedDate: values?.occupationStartedDate || undefined,
          smoker: values?.smoker || false,
          hasPrivateHealthCover: values?.hasPrivateHealthCover || false,
          occupation: values?.occupation || undefined,
          employerName: values?.employerName || undefined,
          employmentStatus: values?.employmentStatus || undefined,
          employmentType: values?.employmentType || undefined,
          hoursWorked: values?.hoursWorked || undefined,
          yearsInCurrentOccupation: values?.yearsInCurrentOccupation || undefined,
          taxFileNumber: values?.taxFileNumber || undefined,
          percentage: values?.percentage || 0,
          clientType: values?.clientType || 'Client',
        },
        companyId: companyData?._id,
      };
      const handleSaveSuccessCallback = (response) => {
        submitProps?.setSubmitting?.(false);
        handleRefetchCompanyData?.();
        if (isAutoClose) {
          handleCloseDialog?.();
        }
        setIsExistingClient(false);
        setSelectedExistingClientData(null);
        submitProps?.resetForm?.();
      };
      const handleSaveFailedCallback = (error) => {
        submitProps?.setSubmitting?.(false);
      };
      dispatch(
        requestSaveCompanyShareholder(
          companyShareholderSaveData,
          handleSaveSuccessCallback,
          handleSaveFailedCallback
        )
      );
    } else {
      const companyShareholderUpdateData = {
        requestBody: {
          isExistingClient: values?.isExistingClient,
          directorId: values?.directorId || undefined,
          clientId: values?.clientId || undefined,
          smsfId: values?.smsfId || undefined,
          companyId: values?.companyId || undefined,
          trustId: values?.trustId || undefined,
          title: values?.title || undefined,
          firstName: values?.firstName || undefined,
          middleName: values?.middleName || undefined,
          surName: values?.surName || undefined,
          preferredName: values?.preferredName || undefined,
          occupationStatus: values?.occupationStatus || undefined,
          dateOfBirth: values?.dateOfBirth || undefined,
          gender: values?.gender || undefined,
          maritalStatus: values?.maritalStatus || undefined,
          primaryMobileNumber: values?.primaryMobileNumber || undefined,
          primaryEmailAddress: values?.primaryEmailAddress || undefined,
          occupationStartedDate: values?.occupationStartedDate || undefined,
          smoker: values?.smoker || false,
          hasPrivateHealthCover: values?.hasPrivateHealthCover || false,
          occupation: values?.occupation || undefined,
          employerName: values?.employerName || undefined,
          employmentStatus: values?.employmentStatus || undefined,
          employmentType: values?.employmentType || undefined,
          hoursWorked: values?.hoursWorked || undefined,
          yearsInCurrentOccupation: values?.yearsInCurrentOccupation || undefined,
          taxFileNumber: values?.taxFileNumber || undefined,
          percentage: values?.percentage || 0,
          clientType: values?.clientType || 'Client',
        },
        companyId: companyData?._id,
        shareholderId: companyShareholderEditData?._id,
      };
      const handleUpdateRequestSuccessCallback = (response) => {
        submitProps?.setSubmitting?.(false);
        handleRefetchCompanyData?.();
        if (isAutoClose) {
          handleCloseDialog?.();
        }
        setIsExistingClient(false);
        setSelectedExistingClientData(null);
        submitProps?.resetForm?.();
      };
      const handleUpdateRequestFailedCallback = (error) => {
        submitProps?.setSubmitting?.(false);
      };

      dispatch(
        requestUpdateCompanyShareholder(
          companyShareholderUpdateData,
          handleUpdateRequestSuccessCallback,
          handleUpdateRequestFailedCallback
        )
      );
    }
  };
  /**
   * Request trust client list and set data by name filter
   * @param {*} searchText
   */
  const handleFilterExistingTrustByName = (searchText) => {
    const handleSetTrustShareholders = (result) => {
      const clientOptions =
        Array.isArray(result) &&
        result?.map &&
        result.map((trustDetail) => ({
          ...trustDetail,
          id: trustDetail?._id,
          value: trustDetail?._id,
          name: trustDetail?.name,
          label: trustDetail?.name,
        }));
      setCompanyShareholderClientOptions(clientOptions);
    };

    if (!trustClientListData?.length) {
      dispatch(requestTrustClientList(handleSetTrustShareholders));
    } else {
      handleSetTrustShareholders(trustClientListData);
    }
  };
  /**
   * Request company client list and set data by name filter
   * @param {*} searchText
   */
  const handleFilterExistingCompanyByName = (searchText) => {
    const handleSetCompanyShareholders = (result) => {
      const clientOptions =
        Array.isArray(result) &&
        result?.map &&
        result
          .map((companyDetail) => ({
            ...companyDetail,
            id: companyDetail?._id,
            value: companyDetail?._id,
            name: companyDetail?.name,
            label: companyDetail?.name,
          }))
          .filter((companyDetail) => companyDetail?._id !== companyData?._id);
      setCompanyShareholderClientOptions(clientOptions);
    };

    if (!companyClientListData?.length) {
      dispatch(requestCompanyClientList(handleSetCompanyShareholders));
    } else {
      handleSetCompanyShareholders(companyClientListData);
    }
  };
  /**
   * Request smsf client list and set data by name filter
   * @param {*} searchText
   */
  const handleFilterExistingSMSFByName = (searchText) => {
    const handleSetSMSFShareholders = (result) => {
      const clientOptions =
        Array.isArray(result) &&
        result?.map &&
        result.map((smsfDetail) => ({
          ...smsfDetail,
          id: smsfDetail?._id,
          value: smsfDetail?._id,
          name: smsfDetail?.name,
          label: smsfDetail?.name,
        }));
      setCompanyShareholderClientOptions(clientOptions);
    };
    if (!smsfClientListData?.length) {
      dispatch(requestSMSFClientList(handleSetSMSFShareholders));
    } else {
      handleSetSMSFShareholders(smsfClientListData);
    }
  };
  /**
   * Handle search clients by filter
   * @param {string} searchText
   */
  const handleFilterExistingClientsByName = (searchText) => {
    const handleSuccessCallback = (response) => {
      const clientData = ((Array.isArray(response) && response) || []).map((resData) => {
        return {
          id: resData?._id,
          value: resData?._id,
          name: resData?.surName + ' ' + resData?.firstName,
          label: resData?.surName + ' ' + resData?.firstName,
          clientType: resData?.type,
        };
      });

      setCompanyShareholderClientOptions(clientData);
    };

    const handleFailedCallback = (error) => {
      console.log(error);
    };

    searchText &&
      searchText.length > 2 &&
      dispatch(
        requestFilterPrimaryClients(
          { name: searchText },
          handleSuccessCallback,
          handleFailedCallback
        )
      );
  };
  /**
   * Handle get directors
   * @param {*} searchText
   */
  const handleFilterExistingDirectorByName = (searchText) => {
    const directorOptions =
      Array.isArray(selectedCompanyClientDetail?.director) &&
      selectedCompanyClientDetail?.director?.map &&
      selectedCompanyClientDetail?.director.map((directorDetail) => {
        const directorName =
          (directorDetail?.title &&
            directorDetail.title +
              ' ' +
              ((directorDetail?.surName &&
                directorDetail?.firstName + ' ' + directorDetail.surName) ||
                directorDetail.firstName)) ||
          (directorDetail?.surName && directorDetail?.firstName + ' ' + directorDetail?.surName) ||
          directorDetail?.firstName;
        return {
          ...directorDetail,
          id: directorDetail?._id,
          value: directorDetail?._id,
          name: directorName,
          label: directorName,
        };
      });
    setCompanyShareholderClientOptions(directorOptions);
  };
  /**
   * Handle set dropdown value
   * @param {*} formik
   * @param {*} value
   */
  const handleSetClientDropdownValue = (formik, value, otherData) => {
    const shareholderExistingType = formik?.values?.shareholderExistingType;
    if (shareholderExistingType === 'Director') {
      formik.setFieldValue('directorId', value);
      const foundDirectorData =
        Array.isArray(selectedCompanyClientDetail?.director) &&
        selectedCompanyClientDetail.director.find(
          (directorDetail) => directorDetail?._id === value
        );
      if (foundDirectorData?._id) {
        setSelectedExistingClientData(foundDirectorData);
      }
    } else if (shareholderExistingType === 'Individual') {
      formik.setFieldValue('clientId', value);
      formik.setFieldValue('clientType', otherData?.clientType);

      // const foundClientData =
      //   Array.isArray(filterClientsRequestSuccessData?.data) &&
      //   filterClientsRequestSuccessData.data.find((clientData) => clientData?._id === value);
      if (value) {
        handleFindClientDetails(value, otherData?.clientType);
      }
    } else if (shareholderExistingType === 'SMSF') {
      formik.setFieldValue('smsfId', value);
      const foundSmsfDetail =
        Array.isArray(smsfClientListData) &&
        smsfClientListData.find((smsfDetail) => smsfDetail?._id === value);
      if (foundSmsfDetail?._id) {
        setSelectedExistingClientData(foundSmsfDetail);
      }
    } else if (shareholderExistingType === 'Company') {
      formik.setFieldValue('companyId', value);
      const foundCompanyDetail =
        Array.isArray(companyClientListData) &&
        companyClientListData.find((companyDetail) => companyDetail?._id === value);
      if (foundCompanyDetail?._id) {
        setSelectedExistingClientData(foundCompanyDetail);
      }
    } else if (shareholderExistingType === 'Trust') {
      formik.setFieldValue('trustId', value);
      const foundTrustDetail =
        Array.isArray(trustClientListData) &&
        trustClientListData.find((trustDetail) => trustDetail?._id === value);
      if (foundTrustDetail?._id) {
        setSelectedExistingClientData(foundTrustDetail);
      }
    }
  };
  /**
   * Find selected client's details
   * @param {*} clientId
   */
  const handleFindClientDetails = (
    clientId,
    clientType,
    isSetsToField = false,
    formikContext = null
  ) => {
    const requestData = {
      clientId,
    };
    const handleClientFindSuccessCallback = (response) => {
      if (response?.data?._id) {
        if (clientType === 'Partner') {
          const newObject = {
            ...response?.data.partner,
            _id: response?.data?._id,
            id: response?.data?._id,
            clientType: 'Partner',
          };
        
          setSelectedExistingClientData(newObject);
          if (isSetsToField) {
            formikContext?.setFieldValue && formikContext.setFieldValue('clientId', clientId);
            formikContext?.setFieldTouched && formikContext.setFieldTouched('clientId', true, true);
          }
        } else {
          setSelectedExistingClientData(response?.data);
          if (isSetsToField) {
            formikContext?.setFieldValue && formikContext.setFieldValue('clientId', clientId);
            formikContext?.setFieldTouched && formikContext.setFieldTouched('clientId', true, true);
          }
        }
      }
    };
    dispatch(requestClientDetail(requestData, handleClientFindSuccessCallback));
  };

  return (
    <Grid container spacing={gridSpacing} sx={{ position: 'relative', maxWidth: '55vw' }}>
      <Grid item xs={12}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange
        >
          {(formik) => {
            const shareholderExistingType = formik?.values?.shareholderExistingType || 'Individual';
            const shareholderExistingTypeIdKey =
              (shareholderExistingType === 'Director'
                ? 'directorId'
                : shareholderExistingType === 'Individual'
                ? 'clientId'
                : shareholderExistingType === 'SMSF'
                ? 'smsfId'
                : shareholderExistingType === 'Company'
                ? 'companyId'
                : shareholderExistingType === 'Trust' && 'trustId') || 'clientId';
            const shareholderExistingTypeLabel =
              ((shareholderExistingType === 'Director'
                ? 'Director '
                : shareholderExistingType === 'Individual'
                ? ''
                : shareholderExistingType === 'SMSF'
                ? 'SMSF '
                : shareholderExistingType === 'Company'
                ? 'Company '
                : shareholderExistingType === 'Trust' && 'Trust ') || '') + 'Client';
            return (
              <Form noValidate>
                <Box className={formSubContainer}>
                  <EditInitialDataFormik
                    editData={companyShareholderEditData}
                    setClientOptions={setCompanyShareholderClientOptions}
                    handleFindClientDetails={handleFindClientDetails}
                    isExistingClient={isExistingClient}
                    isShareholder={true}
                    setIsExistingClient={setIsExistingClient}
                    selectedExistingClientData={selectedExistingClientData}
                    setSelectedExistingClientData={setSelectedExistingClientData}
                  />
                  <Grid container spacing={gridSpacing} alignItems="center">
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      {/* <FormikCheckbox
                        name={'isExistingClient'}
                        label={'Existing Client'}
                        checked={!!formik?.values?.isExistingClient}
                        onChange={(event) => {
                          setIsExistingClient(event?.target?.checked);
                          formik.setFieldValue('isExistingClient', !!event?.target?.checked);
                        }}
                        disabled={formik?.isSubmitting}
                      /> */}
                    </Grid>
                    <Grid item xs={12} sm={6} md={8} lg={9}>
                      <Stack direction="row" alignItems="flex-start" flexWrap="nowrap">
                        <AutoClearClientSearchFormik
                          setSelectedExistingClientData={setSelectedExistingClientData}
                          isShareholderClear={true}
                          isEditMode={isEditMode}
                          setCompanyShareholderClientOptions={setCompanyShareholderClientOptions}
                        />
                        <DropDown
                          name="shareholderExistingType"
                          value={formik?.values?.shareholderExistingType || ''}
                          optionData={shareholderExistingTypeOptions}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={formik.isSubmitting || !formik.values?.isExistingClient}
                          className={combinedFieldLeftSelect}
                          margin="dense"
                        />
                      
                        <DropDownAutoCom
                          fieldName={shareholderExistingTypeIdKey}
                          label={shareholderExistingTypeLabel}
                          optionData={companyShareholderClientOptions || []}
                          dropDownValue={formik.values?.[shareholderExistingTypeIdKey]}
                          placeholder="Please Select !"
                          error={
                            formik.touched?.[shareholderExistingTypeIdKey] &&
                            formik.errors?.[shareholderExistingTypeIdKey]
                          }
                          helperText={
                            formik.touched?.[shareholderExistingTypeIdKey] &&
                            formik.errors?.[shareholderExistingTypeIdKey]
                          }
                          setDropDownValue={(value, otherData) => {
                            handleSetClientDropdownValue(formik, value, otherData);
                          }}
                          onFieldBlur={formik.handleBlur}
                          disabled={formik.isSubmitting || !formik.values?.isExistingClient}
                          loading={!!filterClientsRequestLoading}
                          onInputChange={(event, value, reason) =>
                            handleClientSearchChange(
                              event,
                              value,
                              reason,
                              formik?.values?.shareholderExistingType
                            )
                          }
                          className={combinedFieldRightInput}
                          margin="normal"
                          textFieldMargin="none"
                          textFieldProps={{
                            sx: {
                              mindWidth: '25ch',
                            },
                          }}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Divider className={mainDivider} />
                  <Fade in={isClientDetailRequestLoading}>
                    <LinearProgress
                      color="primary"
                      sx={(theme) => ({
                        width: '100%',
                        height: '0.25rem',
                        borderRadius: theme.shape.globalBorderRadius,
                        [`&.${linearProgressClasses.colorPrimary}`]: {
                          backgroundColor:
                            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                        },
                        [`& .${linearProgressClasses.bar}`]: {
                          borderRadius: 5,
                          backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
                        },
                      })}
                    />
                  </Fade>
                </Box>
                <Box className={formSubContainer}>
                  <Grid container item xs={12}>
                    <CurrencyFieldATM
                      label={'Percentage'}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name={'percentage'}
                      value={formik.values?.percentage}
                      error={formik?.errors?.percentage && formik?.touched?.percentage}
                      helperText={
                        formik?.errors?.percentage &&
                        formik?.touched?.percentage &&
                        formik.errors?.percentage
                      }
                    />
                  </Grid>
                </Box>

                <Box>
                  <Divider className={mainDivider} />
                </Box>
                <Grid container>
                  <Grow
                    in={
                      formik?.values?.shareholderExistingType === 'Individual' ||
                      formik?.values?.shareholderExistingType === 'Director'
                    }
                    unmountOnExit
                  >
                    <Grid item xs={12}>
                      <Box className={formSubContainer}>
                        <Grid container spacing={gridSpacing}>
                          <Grid container item xs={12} sm={12} md={4} lg={3}>
                            <DropDownAutoCom
                              fieldName="title"
                              label={'Title*'}
                              optionData={optionsData?.titleOptions || []}
                              dropDownValue={formik.values?.title}
                              placeholder="Please Select !"
                              error={formik.touched?.title && formik.errors?.title}
                              helperText={formik.touched?.title && formik.errors?.title}
                              setDropDownValue={(value) => formik.setFieldValue('title', value)}
                              onFieldBlur={formik.handleBlur}
                              disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                              required
                            />
                          </Grid>
                          <Grid container item xs={12} sm={12} md={4} lg={3}>
                            <FormikTextField
                              name={'firstName'}
                              type="text"
                              label={'First Name *'}
                              required
                              disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                            />
                          </Grid>
                          <Grid container item xs={12} sm={12} md={4} lg={3}>
                            <FormikTextField
                              name={'middleName'}
                              type="text"
                              label={'Middle Name'}
                              required
                              disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                            />
                          </Grid>
                          <Grid container item xs={12} sm={12} md={4} lg={3}>
                            <FormikTextField
                              name={'surName'}
                              type="text"
                              label={'Surname *'}
                              required
                              disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                            />
                          </Grid>
                          <Grid container item xs={12} sm={12} md={4} lg={3}>
                            <FormikTextField
                              name={'preferredName'}
                              type="text"
                              label={'Preferred Name *'}
                              required
                              disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                            />
                          </Grid>
                          <Grid container item xs={12} sm={12} md={4} lg={3}>
                            <DropDownAutoCom
                              fieldName="gender"
                              label={'Gender *'}
                              optionData={optionsData?.gender || []}
                              dropDownValue={formik.values?.gender || null}
                              placeholder="Please Select !"
                              error={formik.touched?.gender && formik.errors?.gender}
                              helperText={formik.touched?.gender && formik.errors?.gender}
                              setDropDownValue={(value) => formik.setFieldValue('gender', value)}
                              onFieldBlur={formik.handleBlur}
                              disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                            />
                          </Grid>

                          <Grid container item xs={12} sm={12} md={4} lg={3}>
                            <Box style={{ width: '100%' }}>
                              <FormikDatePicker
                                name={'dateOfBirth'}
                                label={'Date of Birth *'}
                                value={formik.values.dateOfBirth}
                                setFieldValue={formik.setFieldValue}
                                error={formik?.errors?.dateOfBirth}
                                views={['year', 'month', 'day']}
                                inputFormat="dd/MM/yyyy"
                                maxDate={new Date()}
                                InputProps={{
                                  onBlur: (event) =>
                                    formik?.setFieldTouched &&
                                    formik.setFieldTouched('dateOfBirth', true, true),
                                }}
                                disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                              />
                            </Box>
                          </Grid>
                          <Grid container item xs={12} sm={12} md={4} lg={3}>
                            <FormikTextField
                              name={'age'}
                              type="text"
                              label={'Age'}
                              disabled
                              required
                              shrink
                            />
                            <DynamicAgeGenerator type="CLIENT" />
                          </Grid>
                          <Grid container item xs={12} sm={12} md={4} lg={3}>
                            <DropDownAutoCom
                              fieldName="maritalStatus"
                              label={'Marital Status *'}
                              optionData={optionsData?.maritalStatus || []}
                              dropDownValue={formik.values?.maritalStatus}
                              placeholder="Please Select !"
                              error={formik.touched?.maritalStatus && formik.errors?.maritalStatus}
                              helperText={
                                (formik.touched?.maritalStatus && formik.errors?.maritalStatus) ||
                                ''
                              }
                              setDropDownValue={(value) =>
                                formik.setFieldValue('maritalStatus', value)
                              }
                              onFieldBlur={formik.handleBlur}
                              disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                            />
                          </Grid>
                          <Grid item xs={12} md={4} lg={3}>
                            <FormikTextField
                              name={'primaryEmailAddress'}
                              type="text"
                              label={'Primary Email Address* '}
                              required
                              disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <Box>
                        <Divider className={mainDivider} />
                      </Box>
                      <Box className={formSubContainer}>
                        <DynamicOccupationFieldGeneratorFormik
                          occupationOptions={optionsData?.occupations || []}
                        />
                        <Grid container spacing={gridSpacing}>
                          <Grid container item xs={12} sm={12} md={4} lg={3}>
                            <DropDownAutoCom
                              fieldName="occupationStatus"
                              label={'Occupation status'}
                              optionData={optionsData.occupationStatuses || []}
                              dropDownValue={formik.values?.occupationStatus}
                              placeholder="Please Select !"
                              error={
                                formik.touched?.occupationStatus && formik.errors?.occupationStatus
                              }
                              helperText={
                                (formik.touched?.occupationStatus &&
                                  formik.errors?.occupationStatus) ||
                                ''
                              }
                              setDropDownValue={(value) =>
                                formik.setFieldValue('occupationStatus', value)
                              }
                              onFieldBlur={formik.handleBlur}
                              disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                            />
                          </Grid>
                          <Fade
                            unmountOnExit
                            in={
                              !formik?.values?.occupationStatus ||
                              formik?.values?.occupationStatus !== 'Unspecified'
                            }
                          >
                            <Grid container item xs={12} sm={12} md={4} lg={3}>
                              <DropDownAutoCom
                                fieldName="occupation"
                                label={'Occupations'}
                                optionData={optionsData.occupations || []}
                                dropDownValue={formik.values?.occupation || ''}
                                placeholder="Please Select !"
                                error={formik.touched?.occupation && formik.errors?.occupation}
                                helperText={
                                  (formik.touched?.occupation && formik.errors?.occupation) || ''
                                }
                                setDropDownValue={(value) => {
                                  formik.setFieldValue('occupation', value);
                                }}
                                onFieldBlur={formik.handleBlur}
                                disabled={
                                  formik.isSubmitting ||
                                  formik?.values?.occupationStatus === 'Home Duties' ||
                                  formik?.values?.occupationStatus === 'Retired' ||
                                  formik?.values?.isExistingClient
                                }
                              />
                            </Grid>
                          </Fade>
                          <Fade
                            unmountOnExit
                            in={
                              !formik?.values?.occupationStatus ||
                              formik?.values?.occupationStatus === 'Working'
                            }
                          >
                            <Grid container item xs={12} sm={12} md={4} lg={3}>
                              <Box style={{ width: '100%' }}>
                                <FormikDatePicker
                                  name={'occupationStartedDate'}
                                  label={'Occupation start date'}
                                  value={formik.values.occupationStartedDate}
                                  setFieldValue={formik.setFieldValue}
                                  error={formik?.errors?.occupationStartedDate}
                                  InputProps={{
                                    onBlur: (event) =>
                                      formik?.setFieldTouched &&
                                      formik.setFieldTouched('occupationStartedDate', true, true),
                                  }}
                                  placeholder="Enter Here.."
                                  disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                                />
                              </Box>
                            </Grid>
                          </Fade>

                          <Fade
                            unmountOnExit
                            in={
                              !formik?.values?.occupationStatus ||
                              formik?.values?.occupationStatus === 'Working'
                            }
                          >
                            <Grid container item xs={12} sm={12} md={4} lg={3}>
                              <FormikTextField
                                name={'employerName'}
                                type="text"
                                label={'Employer name'}
                                required
                                placeholder="Enter Here.."
                                disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                              />
                            </Grid>
                          </Fade>

                          <Fade
                            unmountOnExit
                            in={
                              !formik?.values?.occupationStatus ||
                              formik?.values?.occupationStatus === 'Working'
                            }
                          >
                            <Grid container item xs={12} sm={12} md={4} lg={3}>
                              <DropDownAutoCom
                                fieldName="occupation"
                                label={'Employment status'}
                                optionData={optionsData.employmentStatuses || []}
                                dropDownValue={formik.values?.employmentStatus}
                                placeholder="Please Select !"
                                error={
                                  formik.touched?.employmentStatus &&
                                  formik.errors?.employmentStatus
                                }
                                helperText={
                                  (formik.touched?.employmentStatus &&
                                    formik.errors?.employmentStatus) ||
                                  ''
                                }
                                setDropDownValue={(value) =>
                                  formik.setFieldValue('employmentStatus', value)
                                }
                                onFieldBlur={formik.handleBlur}
                                disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                              />
                            </Grid>
                          </Fade>

                          <Fade
                            unmountOnExit
                            in={
                              !formik?.values?.occupationStatus ||
                              formik?.values?.occupationStatus === 'Working'
                            }
                          >
                            <Grid container item xs={12} sm={12} md={4} lg={3}>
                              <DropDownAutoCom
                                fieldName="employmentType"
                                label={'Employment type'}
                                optionData={optionsData.employmentTypes || []}
                                dropDownValue={formik.values?.employmentType}
                                placeholder="Please Select !"
                                error={
                                  formik.touched?.employmentType && formik.errors?.employmentType
                                }
                                helperText={
                                  (formik.touched?.employmentType &&
                                    formik.errors?.employmentType) ||
                                  ''
                                }
                                setDropDownValue={(value) =>
                                  formik.setFieldValue('employmentType', value)
                                }
                                onFieldBlur={formik.handleBlur}
                                disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                              />
                            </Grid>
                          </Fade>
                          <Fade
                            unmountOnExit
                            in={
                              !formik?.values?.occupationStatus ||
                              formik?.values?.occupationStatus === 'Working'
                            }
                          >
                            <Grid container item xs={12} sm={12} md={4} lg={3}>
                              <FormikTextField
                                name={'hoursWorked'}
                                type="number"
                                label={'Hours worked *'}
                                required
                                placeholder="Enter Here.."
                                disabled={
                                  !!(formik?.values?.employmentType === 'Full-Time') ||
                                  formik?.isSubmitting ||
                                  formik?.values?.isExistingClient
                                }
                              />
                              <DynamicHoursWorkedGeneratorFormik type="CLIENT" />
                            </Grid>
                          </Fade>
                          <Fade
                            unmountOnExit
                            in={
                              !formik?.values?.occupationStatus ||
                              formik?.values?.occupationStatus === 'Working'
                            }
                          >
                            <Grid container item xs={12} sm={12} md={4} lg={3}>
                              <FormikTextField
                                name={'yearsInCurrentOccupation'}
                                type="number"
                                label={'Years in current occupation'}
                                required
                                placeholder="Enter Here.."
                                disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                              />
                            </Grid>
                          </Fade>
                        </Grid>
                      </Box>
                      <Box>
                        <Divider className={mainDivider} />
                      </Box>
                      <Box className={formSubContainer}>
                        <Grid container spacing={gridSpacing}>
                          <Grid container item xs={12} sm={12} md={4} lg={3} alignItems={'center'}>
                            <FormikCheckbox
                              name={'hasPrivateHealthCover'}
                              label={'Private Health Care'}
                              disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                            />
                          </Grid>
                          <Grid container item xs={12} sm={12} md={4} lg={3} alignItems={'center'}>
                            <FormikSwitch
                              name={'smoker'}
                              label={'Smoker'}
                              disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                            />
                          </Grid>

                          <Grid container item xs={12} sm={12} md={true}>
                            <FormikTextField
                              name={'taxFileNumber'}
                              type="text"
                              label={'Tax File Number'}
                              required
                              disabled={formik?.isSubmitting || formik.values?.isExistingClient}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grow>
                </Grid>
                <Grid container>
                  <Grow
                    in={
                      formik?.values?.shareholderExistingType === 'SMSF' ||
                      formik?.values?.shareholderExistingType === 'Company' ||
                      formik?.values?.shareholderExistingType === 'Trust'
                    }
                    unmountOnExit
                  >
                    <Grid item xs={12}>
                      <Box className={formSubContainer}>
                        <Grid container spacing={gridSpacing} alignItems="flex-start">
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <FormikTextField name="name" label="Name" disabled />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <FormikTextField name="abn" label="ABN" type="number" disabled />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <FormikTextField name="tfn" label="TFN" type="number" disabled />
                          </Grid>

                          <Grid item xs={12} sm={6} md={4} lg={3}>
                            <FormikDatePicker
                              name="established"
                              label="Established Date"
                              value={formik.values.established}
                              setFieldValue={formik.setFieldValue}
                              error={formik?.errors?.established}
                              InputProps={{
                                onBlur: (event) =>
                                  formik?.setFieldTouched &&
                                  formik.setFieldTouched('established', true, true),
                              }}
                              placeholder="Enter Here.."
                              disabled
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grow>
                </Grid>

                <Box className={formSubContainer}>
                  <Grid container item xs={12} justifyContent={'flex-end'}>
                    <Stack direction="row" spacing={gridSpacing}>
                      <Button
                        id="formResetBtn"
                        onClick={formik.handleReset}
                        variant="outlined"
                        className={clearBtn}
                      >
                        Reset
                      </Button>
                      <Grid item xs="auto">
                        <LoadingButton
                          className={!isEditMode ? saveBtn : updateBtn}
                          id={'calculate-btn'}
                          endIcon={!isEditMode ? <SaveOutlined /> : <Edit />}
                          loading={
                            formik.isSubmitting ||
                            compositeRequestLoading ||
                            isClientDetailRequestLoading ||
                            isCompanyClientDetailRequestLoading
                          }
                          loadingPosition="end"
                          variant="contained"
                          type="submit"
                        >
                          {!isEditMode ? 'Save' : 'Update'}
                        </LoadingButton>
                      </Grid>
                      {!isEditMode && (
                        <Grid item xs="auto">
                          <LoadingButton
                            className={saveBtn}
                            id={'calculate-btn'}
                            endIcon={<AddCircleOutlineRounded />}
                            loading={
                              formik.isSubmitting ||
                              compositeRequestLoading ||
                              isClientDetailRequestLoading ||
                              isCompanyClientDetailRequestLoading
                            }
                            loadingPosition="end"
                            variant="contained"
                            onClick={(event) =>
                              handleSaveAndAddAnotherCompanyShareholder(event, formik)
                            }
                          >
                            Save and Add Another
                          </LoadingButton>
                        </Grid>
                      )}
                    </Stack>
                  </Grid>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Grid>
  );
};

const ManageCompanyShareholderModal = (props) => {
  const {
    isOpened,
    handleClose,
    companyShareholderEditData,
    handleRefetchCompanyData,
    companyData,
  } = props;

  const isEditMode = !!companyShareholderEditData;

  return (
    <CommonModal
      open={isOpened}
      handleDialogOpened={handleClose}
      dialogTitle={!isEditMode ? 'Add Shareholder' : 'Update Shareholder'}
      dialogContent={
        <ModalContent
          handleCloseDialog={handleClose}
          companyShareholderEditData={companyShareholderEditData}
          handleRefetchCompanyData={handleRefetchCompanyData}
          companyData={companyData}
        />
      }
      dialogContentProps={{
        sx: {
          padding: '0 !important',
        },
      }}
      maxWidth="md"
    />
  );
};

ManageCompanyShareholderModal.propTypes = {
  isOpened: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default memo(ManageCompanyShareholderModal);
