import * as React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import PropTypes from 'prop-types';

const DropDownAutoCom = (props) => {
  const {
    id, // field id
    label, // field label
    optionData, // field options
    fieldName, // formik field name
    onFieldBlur, // formik handleBlur
    dropDownValue, // formik value or state
    setDropDownValue, // formik set field value or set state
    error, // formik touched & error -> boolean
    helperText, // formik touched & error string or helperText
    valueKey, // value from option array object parameter key name
    labelKey, // label from option array object parameter key name
    placeholder,
    fullWidth,
    disabled,
    variant,
    textFieldMargin,
    textFieldProps,
    multiple,
    ...otherProps
  } = props;
  const [autocompleteValue, setAutoCompleteValue] = React.useState(multiple ? [] : null);
  /**
   * List Box options data is loading
   */
  const isLoading = React.useMemo(() => {
    let tempLoading = false;
    tempLoading =
      Array.isArray(optionData) &&
      optionData.find((singleOption) => singleOption?.[valueKey] === -1);
    return !!tempLoading;
  }, [optionData]);

  /**
   * Handle on AutoComplete Changed
   * @param {*} event
   * @param {*} value
   * @param {*} reasons
   * @param {*} details
   */
  const onAutoComChange = (event, value, reasons, details) => {
    setAutoCompleteValue(value);
    if (multiple) {
      setDropDownValue(value);
    } else {
      if (value?.[valueKey]) {
        setDropDownValue && setDropDownValue(value?.[valueKey], value);
      } else {
        setDropDownValue && setDropDownValue('');
      }
    }
  };
  /**
   * Effect on Dropdown Value and Option Data
   */
  React.useEffect(() => {
    if (!multiple) {
      if (dropDownValue && !autocompleteValue) {
        const selectedOption =
          Array.isArray(optionData) &&
          optionData.find((singleOption) => singleOption?.[valueKey] === dropDownValue);
        setAutoCompleteValue(selectedOption);
      } else if (!dropDownValue && autocompleteValue) {
        setAutoCompleteValue(null);
      }
    } else {
      if (dropDownValue && Array.isArray(dropDownValue)) {
        const selectedOptions =
          Array.isArray(optionData) &&
          optionData.filter((singleOption) =>
            dropDownValue.find(
              (initialSelection) => singleOption?.[valueKey] === initialSelection?.[valueKey]
            )
          );
        setAutoCompleteValue(selectedOptions);
      } else if (!dropDownValue && autocompleteValue) {
        setAutoCompleteValue([]);
      }
    }
  }, [dropDownValue, optionData, multiple]);

  return (
    <Autocomplete
      fullWidth={fullWidth}
      id={id}
      value={autocompleteValue}
      onChange={onAutoComChange}
      options={Array.isArray(optionData) ? optionData : []}
      getOptionLabel={(option) => (option?.[labelKey] ? option[labelKey] : '')}
      disabled={disabled}
      isOptionEqualToValue={(option, value) => option?.[valueKey] === value?.[valueKey]}
      margin={'dense'}
      loading={isLoading || false}
      size={'small'}
      sx={{
        '& .MuiAutocomplete-input': {
          padding: '8px 4px 8px 6px !important',
        },
        '& .MuiInputLabel-root': {
          transform: 'translate(14px, 14px) scale(1)',
        },
        '& .MuiInputLabel-shrink': {
          transform: 'translate(14px, -9px) scale(0.75)',
        },
      }}
      onBlur={onFieldBlur}
      renderInput={(params) => (
        <TextField
          {...params}
          //   margin={margin}\
          label={label}
          variant={variant}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
          margin={textFieldMargin || 'dense'}
          {...(textFieldProps || {})}
          inputProps={{
            ...params.inputProps,
            name: fieldName,
            onBlur: (event) => {
              params.inputProps?.onBlur && params.inputProps.onBlur(event);
              onFieldBlur && onFieldBlur(event);
            },
            ...(textFieldProps?.inputProps || {}),
          }}
          InputProps={{
            ...(params?.InputProps || {}),
            ...(textFieldProps?.InputProps || {}),
          }}
        />
      )}
      multiple={!!multiple}
      {...otherProps}
    />
  );
};

DropDownAutoCom.defaultProps = {
  fullWidth: true,
  valueKey: 'value',
  labelKey: 'name',
  variant: 'outlined',
};
DropDownAutoCom.propTypes = {
  dropDownValue: PropTypes.any,
  setDropDownValue: PropTypes.func,
  fullWidth: PropTypes.bool,
  id: PropTypes.any,
  valueKey: PropTypes.string,
  optionData: PropTypes.array.isRequired,
  labelKey: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  variant: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.any,
  helperText: PropTypes.string,
};

export default DropDownAutoCom;
